import React, { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import Image from "next/image";
import { resetAllFilters } from "context/app/appActions";
import { checkClickAndKeyPress } from "helpers";
import { windowSizes } from "data/constants";
import OffersFilter from "./OffersFilter";
import PriceFilter from "./PriceFilter";
import ProductLine from "./ProductLine";
import useMediaQuery from "hooks/useMediaQuery";

const { screen_lg } = windowSizes;

export default function Filters({ isFiltersVisible, toggleVisibility }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const matches = useMediaQuery(`(max-width: ${screen_lg}px)`);

  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    let timer;
    if (!isFiltersVisible) {
      timer = setTimeout(() => setOverflow(true), 45);
    }
    if (isFiltersVisible) {
      setOverflow(true);
      timer = setTimeout(() => setOverflow(false), 600);
    }
    return () => {
      setOverflow(false);
      timer && clearTimeout(timer);
    };
  }, [isFiltersVisible]);

  useEffect(() => {
    if (matches && isFiltersVisible) return toggleVisibility(false);
    if (!matches && !isFiltersVisible) toggleVisibility(true);
    // eslint-disable-next-line
  }, [matches]);

  const removeFilters = () => dispatch(resetAllFilters());

  const resetAllFiltersHandler = (e) => checkClickAndKeyPress(e, removeFilters);

  const filtersWrapperCss = `filtersWrapper ${isFiltersVisible ? "visible" : "hidden"}${
    overflow ? " overflow" : ""
  }`;

  const visibilityHandler = (e) => checkClickAndKeyPress(e, toggleVisibility);

  return (
    <div className="filters">
      <div className={filtersWrapperCss}>
        <div className="filtersOpener">
          <h2
            className="title"
            onClick={visibilityHandler}
            onKeyDown={visibilityHandler}
            tabIndex="0"
            aria-label={t("toggle-filters")}
          >
            {t("filters")}
          </h2>
          <span
            className="filtersVisibilityControler"
            onClick={visibilityHandler}
            onKeyDown={visibilityHandler}
            tabIndex="0"
            aria-label={t("toggle-filters")}
          >
            <Image src="/svg/chevronDown.svg" width={21} height={13} alt="toggle sort" />
          </span>
        </div>

        <div className="filtersControls">
          <ProductLine />
          <PriceFilter />
          <OffersFilter />
          <button onClick={resetAllFiltersHandler} onKeyDown={visibilityHandler}>
            {t("clear-all-filters")}
          </button>
        </div>
      </div>
    </div>
  );
}
