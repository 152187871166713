import React from "react";
import { ImageZoom } from "components";

export default function MainImage({ img }) {
  return (
    <div className="productPhoto">
      <ImageZoom src={img?.src} alt={img?.name} css="contain" />
    </div>
  );
}
