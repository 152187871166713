import React, { useEffect } from "react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import useSubscribeToNewsletter from "hooks/useSubscribeToNewsletter";
import { setToast } from "context/app/appActions";
import { isObjectDefined } from "helpers";
import { setToastFormError } from "utils";
import { Input } from "components";

export default function Newsletter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "" },
  });

  const subscribeToNewsletter = useSubscribeToNewsletter();

  const onSubmit = (data) => subscribeToNewsletter(data);

  useEffect(() => {
    if (isObjectDefined(errors)) {
      Object.values(errors).forEach(({ message }) => {
        dispatch(setToast(setToastFormError(message)));
      });
    }
  }, [errors, dispatch]);

  return (
    <form className="newsletter" onSubmit={handleSubmit(onSubmit)}>
      <p>{t("newsletter-p")}</p>
      <Input
        type="email"
        inputProps={{ register }}
        inputData={{ name: "email", placeholder: "placeholder-email" }}
      />
      <button type="submit">{t("submit")}</button>
    </form>
  );
}
