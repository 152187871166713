import React from "react";
import Link from "next/link";
import Image from "components/global/Image";

export default function CatalogueListBanner({ banner, setItemRef, index }) {
  return (
    <div className="catalogueListBanner" ref={setItemRef} data-observer={index}>
      <h2>{banner?.post_title}</h2>
      <p>{banner?.post_content}</p>
      <Link className="button" href={banner?.post_meta?.link?.url ?? ""}>
        {banner?.post_meta?.call_to_action}
        <span className="screen-reader-text">Details</span>
      </Link>
      <div className="overlay" />
      <Image src={banner?.post_meta?.image?.url} alt="banner" />
    </div>
  );
}
