// Globals
import CookieConsent from "./global/CookieConsent";
import Newsletter from "./global/Newsletter";
import ImageZoom from "./global/ImageZoom";
import Analytics from "./global/Analytics";
import Loader from "./global/Loader";
import Metas from "./global/Metas";
import Image from "./global/Image";

import SectionTextWithProducts from "./SectionTextWithProducts";
import BreadCrumbsWithSort from "./BreadCrumbsWithSort";
import BreadCrumbsPage from "./BreadCrumbsPage";
import ContentPanel from "./ContentPanel";
import BreadCrumbs from "./BreadCrumbs";
import ContactForm from "./ContactForm";
import Confetti from "./Confetti";
import Tooltip from "./Tooltip";
import Banner from "./Banner";
import Search from "./Search";
// import Slider from "./Slider";
import Toast from "./Toast";

// catalogue
import ContentPanelWithFilters from "./catalogue/ContentPanelWithFilters";
import CatalogueSortFilters from "./catalogue/CatalogueSortFilters";
import CatalogueListBanner from "./catalogue/CatalogueListBanner";
import CatalogueListItem from "./catalogue/CatalogueListItem";
import SimiliarProducts from "./catalogue/SimiliarProducts";
import NoProducts from "./catalogue/NoProducts";
import Controls from "./catalogue/Controls";

import MainTitleWithFavoriteAndRating from "./catalogue/detail/MainTitleWithFavoriteAndRating";
import MainImageWithSlider from "./catalogue/detail/MainImageWithSlider";
import RatingStatic from "./catalogue/detail/rating/RatingStatic";
import ContentTabs from "./catalogue/detail/tabs/ContentTabs";
import Reviews from "./catalogue/detail/tabs/reviews/Reviews";
import PhotoSlider from "./catalogue/detail/PhotoSlider";
import Rating from "./catalogue/detail/rating/Rating";
import MainImage from "./catalogue/detail/MainImage";
import Delivery from "./catalogue/detail/Delivery";
import Stock from "./catalogue/detail/Stock";
// import Packaging from "./catalogue/detail/Packaging";

//eshop
import FreeShippingSlider from "./eshop/FreeShippingSlider";
import ContinueShopping from "./eshop/ContinueShopping";
import BasketItemsRow from "./eshop/BasketItemsRow";
import TabsNavigation from "./eshop/TabsNavigation";
import OrderItemsRow from "./eshop/OrderItemsRow";
import BasketItems from "./eshop/BasketItems";
import OrderSteps from "./eshop/OrderSteps";
import Table from "./eshop/table/Table";
import SumItems from "./eshop/SumItems";
import SumTotal from "./eshop/SumTotal";

// maps
import Map from "./map/Map";

// user
import UserAccountChangePassword from "./user/account/UserAccountChangePassword";
import DeliveryAndPayment from "./user/deliveryAndPayment/DeliveryAndPayment";
import BillingAndDeliveryAddress from "./user/BillingAndDeliveryAddress";
import UserAccountAddresses from "./user/account/UserAccountAddresses";
import UserAccountOrders from "./user/account/UserAccountOrders";
import InputErrorMessage from "./user/input/InputErrorMessage";
import ContinueAnonymous from "./user/ContinueAnonymous";
import ForgottenPassword from "./user/ForgottenPassword";
import UserAccount from "./user/account/UserAccount";
import ContinueLogin from "./user/ContinueLogin";
import Registration from "./user/Registration";
import ConfirmOrder from "./user/ConfirmOrder";
import Login from "./user/Login";
import Input from "./user/input";

export {
  Analytics,
  Input,
  Loader,
  NoProducts,
  Tooltip,
  Toast,
  Confetti,
  Image,
  ImageZoom,
  // Slider,
  ContactForm,
  ContinueShopping,
  Reviews,
  TabsNavigation,
  Table,
  BasketItemsRow,
  BasketItems,
  FreeShippingSlider,
  SumTotal,
  SumItems,
  OrderSteps,
  Search,
  OrderItemsRow,
  CookieConsent,
  BreadCrumbs,
  BreadCrumbsPage,
  Metas,
  Map,
  InputErrorMessage,
  Registration,
  Login,
  ForgottenPassword,
  ContinueAnonymous,
  ContinueLogin,
  UserAccount,
  UserAccountAddresses,
  UserAccountOrders,
  UserAccountChangePassword,
  BillingAndDeliveryAddress,
  DeliveryAndPayment,
  ConfirmOrder,
  Newsletter,
  Banner,
  SectionTextWithProducts,
  ContentPanelWithFilters,
  ContentPanel,
  CatalogueSortFilters,
  Controls,
  CatalogueListItem,
  BreadCrumbsWithSort,
  CatalogueListBanner,
  Rating,
  RatingStatic,
  // Packaging,
  Stock,
  PhotoSlider,
  MainImage,
  MainImageWithSlider,
  MainTitleWithFavoriteAndRating,
  Delivery,
  ContentTabs,
  SimiliarProducts,
};
