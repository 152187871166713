import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import EshopContext from "context/eshop/eshopContext";
import useCartSumTotal from "hooks/useCartSumTotal";
import useMediaQuery from "hooks/useMediaQuery";
import { windowSizes } from "data/constants";

const { screen_lg } = windowSizes;

export default function SumItems({ showMobileContinue, hideNextSteps = false }) {
  const {
    state: { user },
  } = useContext(EshopContext);

  const { t } = useTranslation();
  const matches = useMediaQuery(`(max-width: ${screen_lg}px)`);

  const formatedTotalPrice = useCartSumTotal();

  const isButtonShowed = matches && !hideNextSteps && !user?.isLoggedIn;
  const buttonProps = isButtonShowed &&
    showMobileContinue && { onClick: showMobileContinue, onKeyDown: showMobileContinue };

  return (
    <div className={`sum-items${hideNextSteps ? " onlyPrice" : ""}`}>
      <strong>{t("sum")}</strong>
      <strong>{formatedTotalPrice}</strong>

      {isButtonShowed && (
        <button {...buttonProps} id="next-step">
          {t("continue")}
        </button>
      )}
    </div>
  );
}
