import { checkClickAndKeyPress } from "helpers";
import React from "react";

export default function InfoWindow({ dealer, onClose }) {
  const closeHandler = (e) =>
    checkClickAndKeyPress(e, () => {
      e.preventDefault();
      e.stopPropagation();
      onClose();
    });

  return (
    <div className="info-window">
      <a href="#" onClick={closeHandler} onKeyDown={closeHandler} className="close" />
      <div className="info">
        <strong>{dealer.place}</strong>
        <address>{dealer.street + " " + dealer.postcode}</address>
      </div>
    </div>
  );
}
