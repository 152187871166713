import { useRouter } from "next/router";
import { CATEGORY_PAGE, FOCUS_PAGE } from "data/constants";

export default function useCategoryParams() {
  const { query, asPath } = useRouter();

  const categoryName = Object.hasOwn(query, CATEGORY_PAGE) ? CATEGORY_PAGE : FOCUS_PAGE;
  const categorySlug = query[categoryName];

  return { categoryName, categorySlug, asPath };
}
