import React from "react";
import PasswordConfirm from "./PasswordConfirm";
import InputEmail from "./InputEmail";
import Password from "./Password";
import InputTel from "./InputTel";
import Checkbox from "./Checkbox";
import TextArea from "./TextArea";
import Select from "./Select";
import Input from "./Input";

export default function Inputs({ type = "", inputProps, inputData }) {
  const props = { ...inputProps, inputData, type };

  switch (type) {
    case "password":
      return <Password {...props} />;

    case "password-confirm":
      return <PasswordConfirm {...props} />;

    case "email":
      return <InputEmail {...props} />;

    case "text":
      return <Input {...props} />;

    case "tel":
      return <InputTel {...props} />;

    case "checkbox":
      return <Checkbox {...props} />;

    case "textarea":
      return <TextArea {...props} />;

    case "select":
      return <Select {...props} />;

    default:
      return null;
  }
}
