import React from "react";
import { useTranslation } from "next-i18next";
import useAnimateQuantity from "hooks/useAnimateQuantity";
import { PlusCircle, MinusCircle } from "public/svg";
import { Tooltip } from "components";

export default function Controls({ amount, stock, add, remove, addProductToBasket }) {
  const { t } = useTranslation();

  const amountRef = useAnimateQuantity(amount);
  const isQuantityReachStock = amount >= stock;
  const isOutOfStock = stock <= 0;

  return (
    <div className="controls">
      <div className="amountControls">
        <div className="amountInner">
          <button className="minus" onClick={remove} onKeyDown={remove} aria-label={t("decrease")}>
            <MinusCircle />
          </button>
          <div ref={amountRef} className="amount">
            {amount}
          </div>
          <Tooltip tooltip={t("reached-stock-limit")} enableTooltip={isQuantityReachStock && !isOutOfStock}>
            <button className="plus" onClick={add} onKeyDown={remove} aria-label={t("increase")}>
              <PlusCircle />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="addToCart" onClick={addProductToBasket} onKeyDown={addProductToBasket}>
        {t("add-to-cart")}
      </div>
    </div>
  );
}
