import React, { memo } from "react";
import Childrens from "./Childrens";

function Categories({ childrens, index }) {
  return (
    <ul className="categoriesBtns" style={{ paddingLeft: `${12 * index}px` }}>
      {childrens?.map((child, index) => (
        <Childrens key={child.id} child={child} index={index} />
      ))}
    </ul>
  );
}

export default memo(Categories);
