import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { getFormatedPrice } from "helpers";
import { Confetti } from "components";
import { Truck } from "public/svg";

export default function FreeShippingSlider({ current, free }) {
  const { t } = useTranslation();

  const progress = Math.min(Math.ceil((current / free) * 100), 100);

  const [forward, setforward] = useState(true);
  const [prevValue, setprevValue] = useState(0);

  useEffect(() => {
    if (prevValue > progress) {
      setforward(false);
    } else {
      setforward(true);
    }
    setprevValue(progress);
    const myTimer = setTimeout(() => {
      setforward(true);
    }, 1050);

    return () => clearTimeout(myTimer);
    // eslint-disable-next-line
  }, [progress]);

  if (!free) return null;

  const sliderProps = {
    style: { width: `${progress}%` },
  };

  return (
    <div id="freeShipping" className={`free-shipping ${current < free ? "not-free" : "free"}`}>
      <div className="info">
        <strong>
          {t("free-shipping-remains")} {getFormatedPrice(Math.max(free - current, 0))}
        </strong>
        <strong>{t("free-shipping")}</strong>
      </div>
      {current > free && <Confetti />}
      <div className="slider">
        <div className="inner">
          <span {...sliderProps}>
            <Truck className={forward ? "forward" : "backward"} />
          </span>
        </div>
      </div>
    </div>
  );
}
