import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { setFiltersAvailability, setFiltersOffer } from "context/app/appActions";

export default function OffersFilter() {
  const { t } = useTranslation();
  const { offer, availability } = useSelector((state) => state.filters);

  const dispatch = useDispatch();

  const setFilterOffer = () => dispatch(setFiltersOffer());

  const setFilterAvailability = () => dispatch(setFiltersAvailability());

  return (
    <div className="controlsRow">
      <h3>{t("Sonstige")}</h3>
      <ul className="checkboxesFilter">
        <li>
          <label>
            <input type="checkbox" name={t("Angebote")} checked={offer} onChange={setFilterOffer} />{" "}
            {t("Angebote")}
          </label>
        </li>
        <li>
          <label>
            <input
              type="checkbox"
              name={t("in-stock")}
              checked={availability}
              onChange={setFilterAvailability}
            />{" "}
            {t("in-stock")}
          </label>
        </li>
      </ul>
    </div>
  );
}
