import React from "react";
import { useTranslation } from "next-i18next";

export default function OrderSteps({ currentStep }) {
  const { t } = useTranslation();

  return (
    <div className="steps">
      <nav>
        <ul>
          <li className={currentStep >= 1 ? "current" : ""}>
            <span>{t("order-step-1")}</span>
          </li>

          <li className={currentStep >= 2 ? "current divider" : "divider"}></li>
          <li className={currentStep >= 2 ? "current" : ""}>
            <span>{t("order-step-2")}</span>
          </li>

          <li className={currentStep >= 3 ? "current divider" : "divider"}></li>
          <li className={currentStep === 3 ? "current" : ""}>
            <span>{t("order-step-3")}</span>
          </li>
        </ul>
      </nav>
    </div>
  );
}
