import React from "react";

export default function Select({ inputData: { name, options = [] }, register }) {
  return (
    <select {...register(name)}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
