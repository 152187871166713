import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import Link from "next/link";
import useSubscribeToNewsletter from "hooks/useSubscribeToNewsletter";
import { BASKET_URL, ORDER_DELIVEY_URL } from "data/constants";
import { initMolliePayment, submitOrder } from "utils";
import EshopContext from "context/eshop/eshopContext";
import useIsFormDirty from "hooks/useIsFormDirty";
import { InputErrorMessage, Loader } from "..";

export default function ConfirmOrder() {
  const { t } = useTranslation();
  const router = useRouter();

  // Context
  const {
    state: {
      cart,
      cartItemsTotal,
      isLoaded,
      user,
      order,
      user: { userInfo, isInvoiceSameAsDelivery, storedDeliveryAddress, storedInvoiceAddress, newsletter },
      order: { payment, delivery },
    },
    dispatchEshop,
  } = useContext(EshopContext);

  const [isOrderInProcess, setIsOrderInProcess] = useState(false);

  useEffect(() => {
    if (
      isLoaded &&
      (cartItemsTotal === 0 || !user?.storedInvoiceAddress || !order?.payment || !order?.delivery) &&
      !isOrderInProcess
    ) {
      router.replace(BASKET_URL);
    }
  }, [isLoaded, cartItemsTotal, router, user, order, isOrderInProcess]);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);

  // State
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentInterval, setPaymentInterval] = useState(false);

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      note: "",
      newsletter: newsletter || false,
    },
  });

  useIsFormDirty(dirtyFields);

  const subscribeToNewsletter = useSubscribeToNewsletter();

  // Init Mollie payment
  const molliePayment = (order) =>
    initMolliePayment({ setPaymentInterval, setPaymentLoading, router, dispatch })(order);

  // Submit order
  const onSubmit = async (data) => {
    setIsOrderInProcess(true);
    submitOrder({
      data,
      cart,
      payment,
      delivery,
      newsletter,
      subscribeToNewsletter,
      userInfo,
      dispatch,
      dispatchEshop,
      storedDeliveryAddress,
      storedInvoiceAddress,
      isInvoiceSameAsDelivery,
      setPaymentLoading,
      router,
      initMolliePayment: molliePayment,
    });
  };

  return (
    <form className="orderSummary" onSubmit={handleSubmit(onSubmit)}>
      <div className="basket-cols">
        <div className="basket-col">
          <Link href={ORDER_DELIVEY_URL} className="button secondary">
            {t("back-to-shippimng-and-payment")}
          </Link>
        </div>

        <div className="basket-col">
          <div className="form user-billing">
            <h2 className="form-headline">{t("confirm-order")}</h2>

            {(isLoading || paymentLoading) && <Loader />}
            {!isLoading && !paymentLoading && !paymentInterval && (
              <>
                <fieldset>
                  <p>{t("order-notes")}</p>

                  <div className="formRow">
                    <textarea {...register("note")} aria-label={t("order-note")} />
                  </div>

                  <div className="formRow">
                    <input
                      {...register("agree", {
                        required: { value: true, message: t("error-agree") },
                      })}
                      id="basket_agree"
                      type="checkbox"
                    />
                    <label htmlFor="basket_agree">
                      {t("agree-with")}{" "}
                      <Link href="/info-hilfe/datenschulzerklarung" target="_blank">
                        Datenschulzerklarung
                      </Link>
                    </label>
                    <InputErrorMessage errors={errors} name="agree" />
                  </div>

                  {!newsletter && (
                    <div className="formRow">
                      <input {...register("newsletter", {})} id="basket_newsletter" type="checkbox" />
                      <label htmlFor="basket_newsletter">{t("interested-receiving-news")}</label>
                    </div>
                  )}
                </fieldset>

                <button type="submit">{t("confirm-order")}</button>
              </>
            )}
            {paymentInterval && <p className="textAlignCenter">{t("payment-checkout-info")}</p>}
          </div>
        </div>
      </div>
    </form>
  );
}
