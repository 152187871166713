import React, { useState, useEffect } from "react";
import { default as NextImage } from "next/image";
import { useTranslation } from "next-i18next";
import { parseImageUrl } from "helpers";

const placeholderUrl = "/images/placeholder.jpg";

export default function ImageZoom({ src = "", alt, width, height, css = "" }) {
  const { t } = useTranslation();

  const [imageUrl, setImageUrl] = useState(null);

  const [imgStyle, setImgStyle] = useState(null);
  const imgSrc = src ? parseImageUrl(src) : placeholderUrl;
  const imgCss = src ? css : "placeholder";

  const hasWidthAndHeight = width && height;

  const mouseMoveHandler = (e) => {
    const { width, height } = e.currentTarget.getBoundingClientRect();
    const { left, bottom } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((bottom - e.clientY) / height) * 100;
    setImgStyle((old) => ({ ...old, backgroundPosition: `${x}% ${y}%` }));
  };

  const mouseLeaveHandler = () =>
    setImgStyle((props) => ({
      ...props,
      backgroundImage: `none`,
    }));

  const mouseEnterHandler = () =>
    setImgStyle((props) => ({
      ...props,
      backgroundImage: `url(${imageUrl})`,
    }));

  const imageProps = {
    ...(hasWidthAndHeight && { width: width, height: height }),
    ...(!hasWidthAndHeight && { fill: true }),
    ...(imgCss && { className: imgCss }),
    src: imgSrc,
    alt: alt ? alt : t("placeholder"),
    sizes: "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw",
    loader: ({ src, width: w, quality }) => {
      const q = quality || 85;
      return `/api/custom-loader?url=${encodeURIComponent(src)}?w=${w}&q=${q}`;
    },
    loading: "eager",
    onLoad: (e) => setImageUrl(e.target.src),
    onMouseMove: mouseMoveHandler,
    onMouseLeave: mouseLeaveHandler,
    onMouseEnter: mouseEnterHandler,
    onError: (e) => {
      e.currentTarget.onerror = null;
      e.currentTarget.src = placeholderUrl;
      e.currentTarget.srcset = "";
      e.currentTarget.style.objectFit = "cover";
    },
  };

  useEffect(() => {
    if (imageUrl)
      setImgStyle({
        backgroundImage: "none",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "150%",
      });
  }, [imageUrl]);

  return (
    <figure style={imgStyle}>
      <NextImage {...imageProps} />
    </figure>
  );
}
