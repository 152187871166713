import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import EshopContext from "context/eshop/eshopContext";
import useIsFormDirty from "hooks/useIsFormDirty";
import { createProductReview } from "utils";
import ReviewsRow from "./ReviewsRow";
import { Loader } from "components";
import NewReview from "./NewReview";

export default function Reviews({ reviews = [], productSlug, isReviewOpen }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);
  const {
    state: {
      user: { isLoggedIn, userInfo },
    },
  } = useContext(EshopContext);
  const [rating, setRating] = useState(0);

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    formState: { errors, dirtyFields },
  } = useForm();

  useIsFormDirty(dirtyFields);

  const submitHandler = (e) => handleSubmit(onSubmit)(e);

  const onSubmit = (data) => {
    reset();
    createProductReview({ slug: productSlug, data, isLoggedIn, userInfo, rating, dispatch });
  };

  useEffect(() => {
    let timer;
    if (isReviewOpen) {
      timer = setTimeout(() => {
        setFocus(isLoggedIn ? "review" : "reviewer");
      }, 100);
    }

    return () => timer && clearTimeout(timer);
  }, [isReviewOpen, setFocus, isLoggedIn]);

  return (
    <section className="review">
      <div className="reviews">
        {!isLoading ? (
          <NewReview onSubmit={submitHandler} register={register} errors={errors} setRating={setRating} />
        ) : (
          <Loader />
        )}

        {reviews.map((review) => (
          <ReviewsRow key={review.id} review={review} />
        ))}
      </div>
    </section>
  );
}
