import { ITEMS_TO_RENDER_DATA } from "data/constants";
import { sessionStorageGetItem } from "helpers";
import { useEffect, useState, useCallback } from "react";

export default function useIsInViewport(rows) {
  const [refArr, setRefArr] = useState([]);
  const [totalItemsToRender, setTotalItemsToRender] = useState(1);

  useEffect(() => {
    setRefArr([]);
  }, [rows]);

  // save totalItemsToRender for scroll restoration
  useEffect(() => {
    if (ITEMS_TO_RENDER_DATA in sessionStorage) {
      setTotalItemsToRender(sessionStorageGetItem(ITEMS_TO_RENDER_DATA));
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!("inviewport" in entry.target.dataset)) {
              entry.target.setAttribute("data-inviewport", "true");

              if (
                Number(entry.target.dataset.observer) % 47 === 0 &&
                Number(entry.target.dataset.observer) > 1
              ) {
                setTotalItemsToRender((itemsToRender) => itemsToRender + 1);
              }
            }
          }
        });
      },
      { threshold: 0.1, rootMargin: "0px" }
    );

    refArr.length > 0 && refArr.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, [refArr]);

  const setRef = useCallback(
    (node) => {
      if (node !== null) {
        const isInArr = !!refArr.find((item) => item.dataset.observer === node.dataset.observer);
        if (!isInArr) setRefArr((oldRefArr) => [...oldRefArr, node]);
      }
    },
    [refArr]
  );

  return { setRef, totalItemsToRender };
}
