import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { SEARCH_URL, TAGS_URL, main_menu, windowSizes } from "data/constants";
import useMediaQuery from "hooks/useMediaQuery";
import Category from "./categories/Category";
import Filters from "./filters/Filters";
import { isBoolean } from "helpers";

const { screen_lg } = windowSizes;

// Distance from top to contentPanelWithFilters
const FROM_TOP = 450;

export default function ContentPanel({ css }) {
  const { asPath } = useRouter();
  const isCategoryEnabled =
    asPath !== main_menu[0].url && asPath !== SEARCH_URL && !asPath.includes(TAGS_URL);
  const elementSizes = useSelector((state) => state.elementSizes);
  const matchesMax = useMediaQuery(`(max-width: ${screen_lg}px)`);

  const [isVisible, setIsVisible] = useState(true);

  const visibilityHandler = (visibleValue = undefined) => {
    setIsVisible((visible) => (isBoolean(visibleValue) ? visibleValue : !visible));

    if (!isVisible && matchesMax && window.scrollY > FROM_TOP) {
      setTimeout(() => window.scrollTo(0, 325), 300);
    }
  };

  const contentPanelWithFiltersCss = `contentPanelWithFilters${matchesMax && isVisible ? " visible" : ""}${
    css ? ` ${css}` : ""
  }`;

  const fromTop = elementSizes.header + elementSizes.breadcrumbsWithSort;
  const top = isVisible && matchesMax ? 0 : !matchesMax ? fromTop + 32 : fromTop + 0;

  return (
    <div className={contentPanelWithFiltersCss} style={{ top }}>
      <div className="contentPanelWithFiltersInner">
        {isCategoryEnabled && <Category />}

        <Filters isFiltersVisible={isVisible} toggleVisibility={visibilityHandler} />
      </div>
    </div>
  );
}
