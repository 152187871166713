import PlusCircle from "./plusCircle.svg";
import MinusCircle from "./minusCircle.svg";
import PlusCircle2 from "./plusCircle2.svg";
import MinusCircle2 from "./minusCircle2.svg";
import Heart from "./heart.svg";
import HeartFill from "./heartFill.svg";
import Star from "./star.svg";
import StarFill from "./starFill.svg";
import StarHalfFill from "./starHalfFilled.svg";
import Box from "./box.svg";
import Truck from "./truck.svg";
import ChevronDown from "./chevronDown.svg";
import House from "./house.svg";
import Left from "./left.svg";
import Right from "./right.svg";
import SearchIcon from "./magnifyingglass.svg";
import Logo from "./logo/logo.svg";
import Facebook from "./square-facebook.svg";
import Instagram from "./instagram.svg";
import Cart from "./cart.svg";
import Person from "./person.svg";
import Xmark from "./xmarkColor.svg";
import ThumbUp from "./thumbsup.svg";
import ThumbDown from "./thumbsdown.svg";
import TrashCircle from "./trashCircle.svg";
import Exclamationmark from "./exclamationmark.svg";
import CheckmarkCircle from "./checkmarkCircle.svg";

export {
  PlusCircle,
  MinusCircle,
  PlusCircle2,
  MinusCircle2,
  Heart,
  HeartFill,
  Star,
  StarFill,
  StarHalfFill,
  Box,
  Truck,
  ChevronDown,
  House,
  Left,
  Right,
  SearchIcon,
  Logo,
  Facebook,
  Instagram,
  Cart,
  Person,
  Xmark,
  ThumbUp,
  ThumbDown,
  TrashCircle,
  Exclamationmark,
  CheckmarkCircle,
};
