import React, { useContext, useState } from "react";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Link from "next/link";
import {
  convertToNumber,
  increaseAmount,
  decreseAmount,
  getFormatedPrice,
  checkAvailability,
  checkClickAndKeyPress,
  isFunction,
} from "helpers";
import { setPageParams, setToast } from "context/app/appActions";
import useAnimateQuantity from "hooks/useAnimateQuantity";
import { setToastProductAddedError } from "utils/toast";
import { addToCart } from "context/eshop/eshopActions";
import EshopContext from "context/eshop/eshopContext";
import { PlusCircle, MinusCircle } from "public/svg";
import { ANGEBOTE_FILTER } from "data/constants";
import { Image, Tooltip } from "components";

const offerDataLabel = { "data-label": "Angebote" };

const CatalogueListItem = ({ row, setItemRef, index }) => {
  const { t } = useTranslation();

  const { id, name, images, price, regular_price, slug, stock, categories } = row;
  // Context
  const dispatch = useDispatch();
  const {
    state: { cart },
    dispatchEshop,
  } = useContext(EshopContext);
  // Router
  const { asPath } = useRouter();

  // Tooltip
  const isOutOfStock = stock <= 0;

  // Amount
  const [quantity, setQuantity] = useState(isOutOfStock ? 0 : 1);

  const amountRef = useAnimateQuantity(quantity);

  const isQuantityReachStock = quantity >= stock;

  const isProductInCart = cart.find((item) => item.id === id);

  const isProductInOffer =
    categories.find(({ slug: catSlug }) => catSlug === ANGEBOTE_FILTER) && offerDataLabel;

  // Handlers
  const decreaseQuantity = () => setQuantity(decreseAmount);
  const decreaseQuantityHandler = (e) => checkClickAndKeyPress(e, decreaseQuantity);

  const increaseQuantity = () => {
    if (isProductInCart) {
      if (checkAvailability(quantity, stock, isProductInCart.amount)) {
        return setQuantity((amount) => amount + 1);
      } else {
        dispatch(setToast(setToastProductAddedError()));
        return setQuantity((amount) => amount);
      }
    }

    setQuantity((amount) => increaseAmount(amount, stock));
  };
  const increaseQuantityHandler = (e) => checkClickAndKeyPress(e, increaseQuantity);

  // Constants
  const linkHref = { pathname: `/product/${encodeURIComponent(slug)}` };

  // Eshop
  const createProductData = () => ({
    id,
    name,
    slug,
    image: { src: images?.[0]?.src, name: images?.[0]?.name },
    price: convertToNumber(price),
    regular_price: convertToNumber(regular_price),
    amount: quantity,
  });

  const checkAmountBeforeAddToCart = () => {
    if (isProductInCart) {
      const { amount } = isProductInCart;
      const stockLeft = stock - amount;

      if (amount < stock && stockLeft >= quantity && stockLeft >= quantity) return true;

      dispatch(setToast(setToastProductAddedError()));
      return false;
    }

    return true;
  };

  const addToBasket = () => {
    const shouldAddToCart = checkAmountBeforeAddToCart();
    if (stock > 0 && shouldAddToCart) {
      dispatchEshop(addToCart(createProductData()));
    }
  };

  const addToBasketHandler = (e) => checkClickAndKeyPress(e, addToBasket);

  const linkHandler = (e) => checkClickAndKeyPress(e, () => dispatch(setPageParams(asPath)));

  const catalogueListItemProps = {
    ...(isFunction(setItemRef) && { ref: setItemRef, "data-observer": index }),
    className: "catalogueListItem",
  };

  return (
    <div {...catalogueListItemProps}>
      <div className="catalogueProduct">
        <Link href={linkHref} onClick={linkHandler} onKeyDown={linkHandler}>
          <div className="listItemName">
            <h2 dangerouslySetInnerHTML={{ __html: name }}></h2>
          </div>

          <div
            className={`listItemImageWrapper${isProductInOffer ? " showBadge" : ""}`}
            {...isProductInOffer}
          >
            <div className="listItemImage">
              <Image src={images?.[0]?.src} alt={images?.[0]?.name} css="contain" />
            </div>
          </div>
        </Link>

        <div className="listItemBottom">
          <div className="listItemPrice">
            <p>
              {stock > 0 ? (
                <strong>{getFormatedPrice(quantity * price)}</strong>
              ) : (
                <span className="e">{t("product-out-of-stock")}</span>
              )}
            </p>
          </div>

          <div className={`listItemControls${isOutOfStock ? " outOfStock" : ""}`}>
            <div className="listItemAmount">
              <div className="listItemAmountInner">
                <button
                  className="minus"
                  onClick={decreaseQuantityHandler}
                  onKeyDown={decreaseQuantityHandler}
                  disabled={isOutOfStock}
                  aria-label={t("decrease")}
                >
                  <MinusCircle />
                </button>

                <div ref={amountRef} className="amount">
                  {quantity}
                </div>

                <Tooltip
                  tooltip={t("reached-stock-limit")}
                  enableTooltip={isQuantityReachStock && !isOutOfStock}
                >
                  <button
                    className="plus"
                    onClick={increaseQuantityHandler}
                    onKeyDown={increaseQuantityHandler}
                    disabled={isOutOfStock}
                    aria-label={t("increase")}
                  >
                    <PlusCircle />
                  </button>
                </Tooltip>
              </div>
            </div>

            <div
              className={`listItemAddToCart${stock <= 0 ? " disabled" : ""}`}
              onClick={addToBasketHandler}
              onKeyDown={addToBasketHandler}
              tabIndex="0"
              role="button"
            >
              {t("add-to-cart")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogueListItem;
