import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useSelector } from "react-redux";
import Image from "next/image";
import { checkClickAndKeyPress, isArrayDefined } from "helpers";
import useMediaQuery from "hooks/useMediaQuery";
import { windowSizes } from "data/constants";
import Categories from "./Categories";

const { screen_sm, screen_lg } = windowSizes;

export default function Category() {
  const { t } = useTranslation();
  const matches = useMediaQuery(`(max-width: ${screen_sm}px)`);
  const matchesLg = useMediaQuery(`(max-width: ${screen_lg}px)`);
  const category = useSelector((state) => state.category);

  const [isVisible, setIsVisible] = useState(matchesLg ? false : true);

  const visibilityHandler = (e) => checkClickAndKeyPress(e, () => setIsVisible((visible) => !visible));

  useEffect(() => {
    if (matchesLg && isVisible) return setIsVisible(false);
    if (!matchesLg && !isVisible) setIsVisible(true);
    // eslint-disable-next-line
  }, [matchesLg]);

  if (!category?.id) return null;

  return (
    <div className={`categories ${isVisible ? "visible" : "hidden"}`}>
      <div className="categoriesHeadlineWrapper">
        <h2
          className={matches ? " hidden" : ""}
          onClick={visibilityHandler}
          onKeyDown={visibilityHandler}
          tabIndex="0"
          aria-label={t("toggle-category")}
        >
          Kategorien
        </h2>
        <span
          className="categoriesVisibilityController"
          onClick={visibilityHandler}
          onKeyDown={visibilityHandler}
          tabIndex="0"
          aria-label={t("toggle-category")}
        >
          <Image src="/svg/chevronDown.svg" width={21} height={13} alt="toggle sort" />
        </span>
      </div>

      <div className="categoriesWrapper categoriesBtns">
        {isArrayDefined(category.children) && <Categories childrens={category.children} />}
      </div>
    </div>
  );
}
