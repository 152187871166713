import React from "react";
import { default as NextImage } from "next/image";
import { useTranslation } from "next-i18next";
import { parseImageUrl } from "helpers";

const placeholderUrl = "/images/placeholder.jpg";

export default function Image({ src = "", alt, width, height, css = "", loading = "lazy" }) {
  const { t } = useTranslation();

  const imgSrc = src ? parseImageUrl(src) : placeholderUrl;
  const imgCss = src ? css : "placeholder";
  const hasWidthAndHeight = width && height;

  const imageProps = {
    ...(hasWidthAndHeight ? { width: width, height: height } : { fill: true }),
    ...(imgCss && { className: imgCss }),
    alt: alt ? alt : t("placeholder"),
    sizes: "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw",
    loader: ({ src, width: w, quality }) => {
      const q = quality || 75;
      return `/api/custom-loader?url=${encodeURIComponent(src)}?w=${w}&q=${q}`;
    },
    loading,
    onError: (e) => {
      e.currentTarget.onerror = null;
      e.currentTarget.src = placeholderUrl;
      e.currentTarget.srcset = "";
      e.currentTarget.style.objectFit = "cover";
    },
  };

  return <NextImage src={imgSrc} {...imageProps} />;
}
