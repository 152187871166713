import React from "react";
import { useTranslation } from "next-i18next";
// import ReviewsResponse from "./ReviewsResponse";
// import { ThumbUp, ThumbDown } from "public/svg";
import RatingStatic from "../../rating/RatingStatic";
import { parseDate } from "helpers";

export default function ReviewsRow({ review }) {
  const { t } = useTranslation();

  return (
    <div className="reviewsRow">
      <div className="reviewsRowHead">
        <div className="reviewsRowHeadLeft">
          <strong>{review.reviewer}</strong> {t("on")} {parseDate(review.date_created)}
        </div>
        <div className="reviewsRowHeadRight">
          <RatingStatic rating={{ average_rating: review.rating }} />
        </div>
      </div>

      <div className="reviewsRowContent" dangerouslySetInnerHTML={{ __html: review.review }} />

      {/*  <div className="reviewsRowRating">
        <span className="thumbButton like">
          <ThumbUp />
          <strong>20</strong>
        </span>
        <span className="thumbButton dislike">
          <ThumbDown />
          <strong>0</strong>
        </span>
      </div> */}

      {/* <ReviewsResponse /> */}
    </div>
  );
}
