import { useEffect } from "react";

export default function useResizeObserver({ ref, onResize }) {
  useEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver((node) => {
      onResize(node);
    });

    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
    // eslint-disable-next-line
  }, [ref.current]);

  return;
}
