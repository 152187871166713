import React from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { ORDER_USER_DATA_URL } from "data/constants";

export default function ContinueAnonymous() {
  const { t } = useTranslation();

  return (
    <div className="form user-anonymous">
      <h1 className="title">{t("no-account")}</h1>
      <p className="info-line">{t("no-registration")}</p>

      <Link href={t(ORDER_USER_DATA_URL)} className="button">
        {t("no-registration-button")}
      </Link>
    </div>
  );
}
