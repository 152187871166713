import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import useIsFormDirty from "hooks/useIsFormDirty";
import { CheckmarkCircle } from "public/svg";
import { changeUserPassword } from "utils";
import { Loader } from "../..";
import Input from "../input";

const defaultValues = {
  old_password: "",
  password: "",
  password_conf: "",
};

export default function PasswordChange() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({ defaultValues });

  useIsFormDirty(dirtyFields);

  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    reset(defaultValues);
    changeUserPassword({ data, setSuccess, dispatch });
  };

  useEffect(() => {
    let timer;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
      }, 3_000);
    }
    return () => timer && clearTimeout(timer);
  }, [success]);

  return (
    <div className="flex">
      <div className="illustration" />
      <div className="form user-pw-change">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="title">{t("password-change")}</h1>

          {!isLoading && !success ? (
            <>
              <fieldset>
                <div className="formRow">
                  <Input
                    type="password"
                    inputProps={{ register, errors }}
                    inputData={{ name: "old_password", placeholder: "old-password" }}
                  />
                </div>

                <div className="formRow">
                  <Input
                    type="password"
                    inputProps={{ register, errors }}
                    inputData={{ name: "password", placeholder: "new-password" }}
                  />
                </div>

                <div className="formRow">
                  <Input
                    type="password-confirm"
                    inputProps={{ register, errors, getValues }}
                    inputData={{ name: "password_conf" }}
                  />
                </div>
              </fieldset>

              <input type="submit" value={t("save")} />
            </>
          ) : isLoading ? (
            <Loader />
          ) : (
            success && <CheckmarkCircle />
          )}
        </form>
      </div>
    </div>
  );
}
