import React from "react";
import { useTranslation } from "next-i18next";
import { Truck } from "public/svg";

export default function Delivery() {
  const { t } = useTranslation();

  return (
    <ul className="delivery">
      <li>
        <span>
          <Truck /> {t("delivery-by-courier-to-address")}
        </span>
        <span>{t("after-tommorrow")}</span>
      </li>
    </ul>
  );
}
