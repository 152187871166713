import Head from "next/head";

const Metas = ({ seo }) => {
  const pageTitle =
    seo?.title?.indexOf("KOH-I-NOOR Deutschland GmbH") > -1
      ? seo.title
      : seo?.title
      ? seo.title + " | Koh-i-noor Deutchland"
      : "Koh-i-noor Deutchland";

  return (
    <Head>
      <title key="title">{pageTitle}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
      {(seo?.description || pageTitle) && (
        <meta name="description" key="description" content={seo?.description || pageTitle} />
      )}
      {seo?.keywords && <meta name="keywords" key="keywords" content={seo.keywords} />}
      {seo?.image && <meta name="og:image" property="og:image" key="og:image" content={seo.image} />}
      {seo?.og_url && <meta name="og:url" property="og:url" key="og:url" content={seo.og_url} />}
      {seo?.og_title && <meta property="og:title" key="og:title" content={seo.og_title} />}
      {seo?.og_locale && <meta property="og:locale" key="og:locale" content={seo.og_locale} />}
      {seo?.description && (
        <meta
          property="og:description"
          name="og:description"
          key="og:description"
          content={seo.description}
        />
      )}
      {seo?.og_type && <meta name="og:type" property="og:type" key="og:type" content={seo.og_type} />}
      {seo?.og_site_name && (
        <meta name="og:site_name" property="og:site_name" key="og:site_name" content={seo.site_name} />
      )}
      <meta property="og:site_name" key="og:site_name" content="kohinoordeutschland.de" />
      <meta name="facebook-domain-verification" content="xutexake033esbsfpo6ptrntbe7p7o" />
      <link rel="alternate" href="https://www.kohinoordeutschland.de" hrefLang="de" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/site.webmanifest"></link>
      <noscript dangerouslySetInnerHTML={{ __html: `<style>body{opacity:1}</style>` }} />
    </Head>
  );
};
export default Metas;
