import React, { useState } from "react";
import { useTranslation } from "next-i18next";
import { InputErrorMessage } from "components";

export default function PasswordConfirm({ register, errors, getValues, inputData }) {
  const { t } = useTranslation();

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const onChangeHandler = () => setIsPasswordShown((isShowed) => !isShowed);

  const passwordConfirmProps = {
    ...register(inputData?.name ? inputData.name : "passwordConfirm", {
      required: { value: true, message: t("empty-password") },
      minLength: {
        value: 6,
        message: t("info-password"),
      },
      validate: (value) => value === getValues("password") || t("error-password-confirm"),
    }),
    placeholder: t("password-confirmation"),
    type: isPasswordShown ? "text" : "password",
  };

  return (
    <>
      <div className="password">
        <input {...passwordConfirmProps} />
        <i onClick={onChangeHandler} />
      </div>
      <InputErrorMessage errors={errors} name={inputData?.name ? inputData.name : "passwordConfirm"} />
    </>
  );
}
