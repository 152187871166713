import React, { useContext, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { setUserOrderDelivery } from "context/eshop/eshopActions";
import InputErrorMessage from "../input/InputErrorMessage";
import EshopContext from "context/eshop/eshopContext";
import { FREE_SHIPPING } from "data/constants";
import { getFormatedPrice } from "helpers";

export default function ShippingMethod({ method, formData: { register, errors } }) {
  const {
    settings: { min_amount, cost },
    description,
    title,
    id,
  } = method;

  const { t } = useTranslation();

  const {
    state: { cartSumTotal, order },
    dispatchEshop,
  } = useContext(EshopContext);

  const onChangeDeliveryHandler = () => dispatchEshop(setUserOrderDelivery(method));

  const isFreeShipping = id === FREE_SHIPPING;
  const isDisabled = isFreeShipping && cartSumTotal < min_amount;

  useEffect(() => {
    if (isDisabled && cartSumTotal < min_amount && order?.delivery?.id === FREE_SHIPPING) {
      dispatchEshop(setUserOrderDelivery(null));
    }
  }, [isDisabled, cartSumTotal, min_amount, dispatchEshop, order?.delivery?.id]);

  return (
    <div className="form-row delivery-options">
      <div className={`delivery-option${method.cost ? " free-now" : ""}`}>
        <div className="delivery-button">
          <input
            {...register("delivery", {
              required: { value: true, message: t("errDel") },
            })}
            type="radio"
            id={`delivery_${id}`}
            value={id}
            disabled={isDisabled}
            onChange={onChangeDeliveryHandler}
          />
          <label htmlFor={`delivery_${id}`}>{title}</label>
        </div>

        {isDisabled && (
          <div className="free-from">
            <p>
              {t("free_from")} {getFormatedPrice(min_amount || cost)}
            </p>
          </div>
        )}

        {description && (
          <div className="delivery-description" dangerouslySetInnerHTML={{ __html: description }}></div>
        )}

        <div className="delivery-price">
          {isFreeShipping && !isDisabled ? t("free") : getFormatedPrice(min_amount || cost)}
        </div>
      </div>

      <InputErrorMessage errors={errors} name="delivery" />
    </div>
  );
}
