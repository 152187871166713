import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import {
  formatNumber,
  decreseAmount,
  increaseAmount,
  formatPrice,
  checkClickAndKeyPress,
  checkAvailability,
} from "helpers";
import { Controls } from "components";
import { setToast } from "context/app/appActions";
import { setToastProductAddedError } from "utils";
import EshopContext from "context/eshop/eshopContext";

export default function Stock({ amount, setAmount, productPrice, stock, addProduct, productId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    state: { cart },
  } = useContext(EshopContext);

  const isProductInCart = cart.find((item) => item.id === productId);

  const minusAmount = () => setAmount(decreseAmount);
  const decreaseAmountHandler = (e) => checkClickAndKeyPress(e, minusAmount);

  const plusAmount = () => {
    if (isProductInCart) {
      if (checkAvailability(amount, stock, isProductInCart.amount)) {
        return setAmount((amount) => amount + 1);
      } else {
        dispatch(setToast(setToastProductAddedError()));
        return setAmount((amount) => amount);
      }
    }

    setAmount((amount) => increaseAmount(amount, stock));
  };

  const increaseAmountHandler = (e) => checkClickAndKeyPress(e, plusAmount);

  const addProductToBasket = () => addProduct(amount);
  const addProductToBasketHandler = (e) => checkClickAndKeyPress(e, addProductToBasket);

  const hasProductPriceSale = productPrice.price !== productPrice.regular_price;
  const price = productPrice.price;
  const regularPrice = productPrice.regular_price;
  const finalPrice = formatPrice(formatNumber(amount * price));

  return (
    <div className="stockWrapper">
      <div className="priceWrapper">
        <div className="price">
          <strong>{finalPrice}</strong>
          {hasProductPriceSale && <p className="regularPrice">{regularPrice}</p>}
        </div>
        <p className={`stock${stock > 0 ? "" : " noStock"}`}>
          {stock > 0 ? t("in-stock") : t("out-of-stock")}
        </p>
      </div>
      <Controls
        amount={amount}
        stock={stock}
        remove={decreaseAmountHandler}
        add={increaseAmountHandler}
        addProductToBasket={addProductToBasketHandler}
      />
    </div>
  );
}
