import React, { useState } from "react";
import dynamic from "next/dynamic";
import { isArrayDefined } from "helpers";
import MainImage from "./MainImage";

const DynamicSlider = dynamic(() => import("./PhotoSlider"), {
  loading: () => <p>Loading...</p>,
});

export default function MainImageWithSlider({ image, images }) {
  const [selectedImage, setSelectedImage] = useState(image);

  const selectImageHandler = (index) => setSelectedImage(images[index]);

  return (
    <>
      <MainImage img={selectedImage} />
      {isArrayDefined(images) && (
        <DynamicSlider imgs={images} selectedImg={selectedImage} selectImage={selectImageHandler} />
      )}
    </>
  );
}
