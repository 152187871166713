import React from "react";
import { useTranslation } from "next-i18next";

export default function Checkbox({ register, inputData: { name, id, isRequired = true, message } }) {
  const { t } = useTranslation();

  const checkboxProps = {
    type: "checkbox",
    ...register(name, {
      required: { value: isRequired, message: message ? t(message) : "" },
    }),
    ...(id && { id }),
  };

  return <input {...checkboxProps} />;
}
