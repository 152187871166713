import { checkClickAndKeyPress } from "helpers";
import React, { useState } from "react";
import { RatingStar, RatingStarFill } from "./ratingStars";

export default function Rating({ setRating = () => undefined }) {
  const [ratingHover, setRatingHover] = useState(0);
  const [rating, setRatingNum] = useState(0);

  const starRatingMouseHandler = (e, i) => {
    setRatingHover(i);
  };

  const starRatingHandler = (i) => {
    setRatingNum(i);
    setRating(i);
  };

  const getRatingStar = (index) => {
    const startProps = { setRating: (e) => checkClickAndKeyPress(e, () => starRatingHandler(index)) };
    if (rating && rating > ratingHover ? rating >= index : ratingHover >= index) {
      return <RatingStarFill {...startProps} />;
    }
    if (rating ? rating !== index : ratingHover !== index) {
      return <RatingStar {...startProps} />;
    }
  };

  return (
    <div className="productRating">
      {[...Array(5).keys()].map((item, i) => (
        <span key={i} className="rating" onMouseMove={(e) => starRatingMouseHandler(e, i + 1)}>
          {getRatingStar(i + 1)}
        </span>
      ))}
    </div>
  );
}
