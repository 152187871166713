import { useContext } from "react";
import { useDispatch } from "react-redux";
import { setToastFormError, setToastFormSuccess } from "utils";
import { setUserNewsletter } from "context/eshop/eshopActions";
import EshopContext from "context/eshop/eshopContext";
import { setToast } from "context/app/appActions";
import { subscribeToNewsletter } from "utils";

export default function useSubscribeToNewsletter() {
  const dispatch = useDispatch();
  const { dispatchEshop } = useContext(EshopContext);

  const onNewsletterSuccess = () => {
    dispatchEshop(setUserNewsletter());
    dispatch(setToast(setToastFormSuccess("newsletter-success")));
  };

  const onNewsletterError = (e) => {
    if (e?.response?.data?.code === "duplicate_parameter") {
      return dispatch(setToast(setToastFormError("duplicate-parameter")));
    }
    dispatch(setToast(setToastFormError(e?.response?.data?.messagew)));
  };

  const onNewsletterSubscribe = (data) =>
    subscribeToNewsletter({ onSuccess: onNewsletterSuccess, onError: onNewsletterError })(data);

  return onNewsletterSubscribe;
}
