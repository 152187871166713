import React from "react";
import { useTranslation } from "next-i18next";
import { formatPrice } from "helpers";
import { Image } from "components";

export default function OrderItemsRow({ cartItem }) {
  const { t } = useTranslation();
  if (!cartItem) return null;

  return (
    <div className="basketItem">
      <div className="td image">
        <figure>
          <Image src={cartItem?.image?.src} alt={cartItem?.img?.id} />
        </figure>
      </div>
      <div className="td name">{cartItem?.name}</div>
      <div className="td price">
        <span className="hint">{t("price")}</span>
        {formatPrice(cartItem?.price)}
      </div>
      <div className="td count">
        <span className="hint">{t("quantity")}</span>
        <div className="quantity sm">{cartItem?.quantity}</div>
      </div>
      <div className="td sub-total">
        <span className="hint">{t("sum")}</span>
        {formatPrice(cartItem?.price * cartItem?.quantity)}
      </div>
    </div>
  );
}
