import React, { useContext, useState } from "react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Link from "next/link";
import { PROFILE_URL, REGISTER_URL, LOST_PW_URL } from "data/constants";
import EshopContext from "context/eshop/eshopContext";
import useIsFormDirty from "hooks/useIsFormDirty";
import { CheckmarkCircle } from "public/svg";
import { Loader, Input } from "components";
import { loginUser } from "utils";

const defaultValues = { email: "", password: "" };

export default function Login({ redirect = true }) {
  const { dispatchEshop } = useContext(EshopContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({ defaultValues });

  useIsFormDirty(dirtyFields);

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigateToProfile = () => (redirect ? router.push(PROFILE_URL) : null);

  const onSubmit = (data) => {
    reset(defaultValues);
    loginUser({ data, dispatchEshop, setIsLoading, setSuccess, onSuccess: navigateToProfile, dispatch });
  };

  return (
    <div className="form user-login">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">{t("login-to-eshop")}</h1>
        <p className="subTitle">{t("login-info")}</p>

        {!isLoading && !success && (
          <>
            <fieldset>
              <div className="formRow">
                <Input
                  type="email"
                  inputProps={{ register, errors }}
                  inputData={{ name: "email", placeholder: "Email", message: "empty-email" }}
                />
              </div>

              <div className="formRow nbp">
                <Input type="password" inputProps={{ register, errors }} />
              </div>
            </fieldset>

            <input type="submit" value={t("login")} />
          </>
        )}

        {isLoading && <Loader />}

        {!isLoading && success && (
          <>
            <CheckmarkCircle />
            <div>
              <p>{t("login-success")}</p>
            </div>
          </>
        )}
      </form>

      <div className="form-actions">
        <p>
          {t("no-account-yet")}{" "}
          <Link href={t(REGISTER_URL)}>
            <strong>{t("register")}</strong>
          </Link>
        </p>
        <p>
          {t("lost-password")}{" "}
          <Link href={t(LOST_PW_URL)}>
            {" "}
            <strong>{t("restore-password")}</strong>
          </Link>
        </p>
      </div>
    </div>
  );
}
