import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import Link from "next/link";
import { ORDER_SUMMARY_URL, ORDER_USER_DATA_URL } from "data/constants";
import EshopContext from "context/eshop/eshopContext";
import { InputErrorMessage, Loader } from "../..";
import { getPaymentAndShipping } from "utils";
import ShippingMethod from "./ShippingMethod";
import PaymentMethod from "./PaymentMethod";

export default function DeliveryAndPayment() {
  const { t } = useTranslation();
  const router = useRouter();

  const isLoading = useSelector((state) => state.isLoading);
  const {
    state: { order },
  } = useContext(EshopContext);

  const [shippingMethods, setShippingMethods] = useState([]);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    getPaymentAndShipping({ shippingMethods, setShippingMethods, payments, setPayments });
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { delivery: order?.id, payment: payments?.id },
  });

  useEffect(() => {
    if (order?.delivery?.id || order?.payment?.id) {
      reset({ delivery: order?.delivery?.id ?? "", payment: order?.payment?.id ?? "" });
    }
  }, [order, payments, reset]);

  const onSubmit = () => router.push(ORDER_SUMMARY_URL);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="basket-cols">
          <div className="basket-col">
            <div className="form deliveries">
              <h2 className="form-headline">{t("delivery")}</h2>
              <fieldset>
                {isLoading && <Loader />}
                {shippingMethods && (
                  <div className="form-row delivery-options">
                    {shippingMethods.map((method) => (
                      <ShippingMethod key={method.id} method={method} formData={{ register, errors }} />
                    ))}
                  </div>
                )}
              </fieldset>
            </div>
          </div>

          <div className="basket-col">
            <div className="form payments">
              <h2 className="form-headline">{t("payment")}</h2>
              <fieldset>
                <div className="form-row payment-options">
                  {isLoading && <Loader />}
                  {payments.map((payment) => (
                    <PaymentMethod payment={payment} formData={{ register }} key={payment.id} />
                  ))}

                  <InputErrorMessage errors={errors} name="payment" />
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="btns-prev-next">
          <Link href={ORDER_USER_DATA_URL} className="button secondary">
            {t("back-to-billing")}
          </Link>
          <button type="submit" className="button">
            {t("go-to-send")}
          </button>
        </div>
      </form>
    </>
  );
}
