import React, { useState } from "react";
import Link from "next/link";
import { checkClickAndKeyPress, isArrayDefined } from "helpers";
import useCategoryParams from "hooks/useCategoryParams";
import { PlusCircle2, MinusCircle2 } from "public/svg";
import Categories from "./Categories";

export default function Childrens({ child, index }) {
  const { categoryName, categorySlug } = useCategoryParams();
  const isOpenInitialState = categorySlug.at(-1) === child.slug ? false : categorySlug.includes(child.slug);

  const [isOpen, setIsOpen] = useState(isOpenInitialState);

  const isActive = categorySlug.at(-1) === child.slug;

  const openCategoryHandler = (e) =>
    checkClickAndKeyPress(e, () => {
      isActive && e.preventDefault();
      setIsOpen((isOpen) => !isOpen);
    });

  const subCategoryLink = () => `/${categoryName}/${child.slugPath}`;

  const linkProps = {
    href: subCategoryLink(child.slug),
    className: `subLink${isActive ? " active" : ""}`,
    ...(isActive && { onClick: openCategoryHandler, onKeyDown: openCategoryHandler }),
  };

  return (
    <>
      <li key={child.id}>
        <Link {...linkProps}>{child.name}</Link>

        {isArrayDefined(child.children) && (
          <span className="categoryOpener" onClick={openCategoryHandler} onKeyDown={openCategoryHandler}>
            {isOpen ? <MinusCircle2 /> : <PlusCircle2 />}
          </span>
        )}
      </li>

      {isOpen && isArrayDefined(child.children) && <Categories childrens={child.children} index={index} />}
    </>
  );
}
