import React from "react";
import { StarFill } from "public/svg";
import { isFunction } from "helpers";

export default function RatingStarFill({ setRating }) {
  const hasOnClick = isFunction(setRating) && {
    onClick: setRating,
    onKeyDown: setRating,
    tabIndex: 0,
  };

  return (
    <figure {...hasOnClick}>
      <StarFill />
    </figure>
  );
}
