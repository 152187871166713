import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import { logOutUser } from "context/eshop/eshopActions";
import EshopContext from "context/eshop/eshopContext";
import { checkClickAndKeyPress } from "helpers";

export default function UserAccountLogout() {
  const { t } = useTranslation();

  const { dispatchEshop } = useContext(EshopContext);

  const logOut = (e) => checkClickAndKeyPress(e, () => dispatchEshop(logOutUser(true)));

  return (
    <div className="flex">
      <div className="illustration" />
      <div className="form logout">
        <h1 className="title">{t("logout")}</h1>
        <button onClick={logOut} onKeyDown={logOut}>
          {t("logout")}
        </button>
      </div>
    </div>
  );
}
