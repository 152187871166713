import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Loader } from "components";
import { isArrayDefined } from "helpers";
import { getUserOrders } from "utils";

export default function UserAccountOrders() {
  const isLoading = useSelector((state) => state.isLoading);
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    if (!orders) getUserOrders(setOrders);
  }, [orders]);

  return (
    <div className="user-profile-orders">
      {isLoading || !orders ? <Loader /> : isArrayDefined(orders) ? <Table data={orders} /> : null}
    </div>
  );
}
