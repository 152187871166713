import React from "react";
import { useTranslation } from "next-i18next";
import useCartSumTotal from "hooks/useCartSumTotal";

export default function SumTotal() {
  const { t } = useTranslation();

  const formatedTotalPrice = useCartSumTotal();

  // const discountSum = 0;

  return (
    <>
      {/*  {discountSum > 0 && (
        <div className="sum sum-discount">
          <strong>{t("discount")}</strong>
          <strong>{formatPrice(discountSum)}</strong>
        </div>
      )} */}
      <div className="sum sum-total onlyPrice">
        <strong>{t("sum")}</strong>
        <strong>{formatedTotalPrice}</strong>
      </div>
    </>
  );
}
