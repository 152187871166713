import React from "react";
import { RatingStar, /* RatingStarHalfFill, */ RatingStarFill } from "./ratingStars";

export default function Rating({
  rating: { average_rating, rating_count },
  showRatingNum = true,
  openRating,
}) {
  const getRatingStar = (index) => {
    if (average_rating >= index) return <RatingStarFill />;
    // if (average_rating % index >= 0.55 && Math.round(average_rating) >= index) return <RatingStarHalfFill />;
    if (average_rating < index) return <RatingStar />;
  };

  return (
    <div className="productRating static" onClick={openRating}>
      {[...Array(5).keys()].map((item, i) => (
        <span key={i} className="rating">
          {getRatingStar(i + 1)}
        </span>
      ))}
      {showRatingNum && <p>{rating_count}</p>}
    </div>
  );
}
