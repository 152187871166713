import { useContext, useEffect, useState } from "react";
import { convertToNumber, getFormatedPrice } from "helpers";
import EshopContext from "context/eshop/eshopContext";
import { FREE_SHIPPING } from "data/constants";

export default function useCartSumTotal() {
  const {
    state: { cartSumTotal, order },
  } = useContext(EshopContext);

  const [deliveryCost, setDeliveryCost] = useState(0);

  useEffect(() => {
    if (order?.delivery) {
      if (order.delivery.id !== FREE_SHIPPING && deliveryCost === 0) {
        const currentDeliveryCost = convertToNumber(order.delivery.settings.cost);
        setDeliveryCost(currentDeliveryCost);
      }

      if (order.delivery.id === FREE_SHIPPING && deliveryCost !== 0) {
        setDeliveryCost(0);
      }
    }

    // eslint-disable-next-line
  }, [order?.delivery]);

  return getFormatedPrice(cartSumTotal + deliveryCost);
}
