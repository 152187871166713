import { FACEBOOK_URL, INSTAGRAM_URL } from "data/constants";
import { Facebook, Instagram } from "public/svg";

export default function Social() {
  return (
    <div className="socials">
      <a href={FACEBOOK_URL} target="_blank" rel="noreferrer noopener" title="Facebook" className="facebook">
        <Facebook />
      </a>
      <a
        href={INSTAGRAM_URL}
        target="_blank"
        rel="noreferrer noopener"
        title="Instagram"
        className="instagram"
      >
        <Instagram />
      </a>
    </div>
  );
}
