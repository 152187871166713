import React from "react";

export default function Tooltip({ tooltip, enableTooltip, children }) {
  return enableTooltip ? (
    <div className="tooltipWrapper">
      <div className="tooltip">
        <div className="info">
          <strong>{tooltip}</strong>
        </div>
      </div>
      {children}
    </div>
  ) : (
    children
  );
}
