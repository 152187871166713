import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { setFiltersSelectedProductLines } from "context/app/appActions";
import { isArrayDefined } from "helpers";

export default function ProductLine() {
  const { t } = useTranslation();
  const { productLines, selectedProductLines } = useSelector((state) => state.filters);

  const dispatch = useDispatch();

  const setAvailability = (e) => dispatch(setFiltersSelectedProductLines(e.target.value));

  return (
    isArrayDefined(productLines) && (
      <div className="controlsRow">
        <h3 className="mb-0">{t("Produkt-linie")}</h3>
        <ul className="checkboxesFilter">
          {productLines.map((item, i) => (
            <li key={i}>
              <label>
                <input
                  type="checkbox"
                  name={item}
                  value={item}
                  checked={selectedProductLines.includes(item)}
                  onChange={setAvailability}
                />
                {` ${item}`}
              </label>
            </li>
          ))}
        </ul>
      </div>
    )
  );
}
