import React from "react";
import { useTranslation } from "next-i18next";
import { useSelector } from "react-redux";
import Link from "next/link";
import { FOCUS_PAGE, CATEGORY_PAGE } from "data/constants";

export default function ContinueShopping() {
  const { t } = useTranslation();
  const backButtonPath = useSelector((state) => state.backButtonPath);

  const backButtonUrl =
    backButtonPath.includes(CATEGORY_PAGE) || backButtonPath.includes(FOCUS_PAGE) ? backButtonPath : "/alles";

  return (
    <Link href={backButtonUrl} className="button">
      {t("continue-shopping")}
    </Link>
  );
}
