import React from "react";
import CatalogueListItem from "./catalogue/CatalogueListItem";
import useIsInViewport from "hooks/useIsInViewport";

const SectionTextWithProducts = ({ rows, text }) => {
  // fade in products
  const { setRef } = useIsInViewport();

  return (
    <section className="textWithProducts">
      <div className="leftCol">
        <p>{text}</p>
      </div>

      <div className="rightCol">
        <div className="productRow">
          {rows.map((row, i) => (
            <CatalogueListItem key={i} row={row} setItemRef={setRef} classIndex={i + 1} index={i} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionTextWithProducts;
