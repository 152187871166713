import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { checkClickAndKeyPress, setSortCss } from "helpers";
import { windowSizes, SORT, ORDER } from "data/constants";
import { setSortFilters } from "context/app/appActions";
import useMediaQuery from "hooks/useMediaQuery";

const { screen_sm } = windowSizes;

export default function CatalogueSortFilters() {
  const { t } = useTranslation();
  const matchesMax = useMediaQuery(`(max-width: ${screen_sm}px)`);
  const dispatch = useDispatch();
  const sort = useSelector((state) => state.filters.sort);
  const [css, setCss] = useState(0);

  useEffect(() => {
    if (sort.order && sort.orderby) {
      setSortCss({ sort, setCss });
    } else if (css !== 0) {
      setCss(0);
    }
  }, [sort, css]);

  const [isVisible, setIsVisible] = useState(false);

  const isActive = (num) => (css === num ? "active" : "");

  const productFilterHandler = (e, orderby, order) =>
    checkClickAndKeyPress(e, () => {
      e.preventDefault();
      dispatch(setSortFilters({ order, orderby }));
    });

  const productOrderFilterHandler = (e, orderby) =>
    checkClickAndKeyPress(e, () => {
      e.preventDefault();
      let order = sort?.order === ORDER.asc ? ORDER.desc : ORDER.asc;
      dispatch(setSortFilters({ order, orderby }));
    });

  const priceAscendingHandler = (e) => productFilterHandler(e, SORT.price, ORDER.asc);
  const priceDescendingHandler = (e) => productFilterHandler(e, SORT.price, ORDER.desc);
  const titleOrderHandler = (e) => productOrderFilterHandler(e, SORT.title);
  const popularityOrderHandler = (e) => productOrderFilterHandler(e, SORT.popularity);
  const ratingOrderHandler = (e) => productOrderFilterHandler(e, SORT.rating);

  const toggleVisibility = () => setIsVisible((visible) => !visible);
  const visibilityHandler = (e) => checkClickAndKeyPress(e, toggleVisibility);

  const navListCss = matchesMax && isVisible && { className: "visible" };

  return (
    <nav className="catalogueSortFilters">
      <div className={`sortOpener${isVisible ? " open" : ""}`} {...(!matchesMax && { hidden: true })}>
        <h2 onClick={visibilityHandler} onKeyDown={visibilityHandler} aria-label={t("toggle-sort")}>
          {t("sort")}
        </h2>
        <span className="sortClose" onClick={visibilityHandler} onKeyDown={visibilityHandler}>
          <Image src="/svg/chevronDown.svg" width={21} height={13} alt={t("toggle-sort")} />
        </span>
      </div>

      <ul {...navListCss}>
        <li>
          <span onClick={priceAscendingHandler} onKeyDown={priceAscendingHandler} className={isActive(1)}>
            {t("by-price-higher")}
          </span>
        </li>
        <li>
          <span onClick={priceDescendingHandler} onKeyDown={priceDescendingHandler} className={isActive(2)}>
            {t("by-price-lower")}
          </span>
        </li>
        <li>
          <span onClick={titleOrderHandler} onKeyDown={titleOrderHandler} className={isActive(3)}>
            {t("alphabetical")}
          </span>
        </li>
        <li>
          <span onClick={popularityOrderHandler} onKeyDown={popularityOrderHandler} className={isActive(4)}>
            {t("best-selling-products")}
          </span>
        </li>
        <li>
          <span onClick={ratingOrderHandler} onKeyDown={ratingOrderHandler} className={isActive(5)}>
            {t("review")}
          </span>
        </li>
      </ul>
    </nav>
  );
}
