import React from "react";
// import Favorite from "./Favorite";
import RatingStatic from "./rating/RatingStatic";

export default function MainTitleWithFavoriteAndRating({ name, rating, openReview }) {
  return (
    <>
      <div className="titleWithFavorites">
        <h2 className="title">{name}</h2>
        {/* <Favorite /> */}
      </div>

      <RatingStatic rating={rating} openRating={openReview} />
    </>
  );
}
