import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { Tabs, Tab, Box as MaterialBox, useMediaQuery } from "@mui/material";
import TechnicalParametersPanel from "./TechnicalParametersPanel";
import DescriptionPanel from "./DescriptionPanel";
import { windowSizes } from "data/constants";
import Reviews from "./reviews/Reviews";

const { screen_md } = windowSizes;

const TabsPanels = [DescriptionPanel, Reviews, TechnicalParametersPanel];
const TabsTitles = ["product-description", "discussions-and-reviews", "technical-parameters"];

export default function CatalogueTabs({ product, isReviewsOpen }) {
  const { t } = useTranslation();

  // Tabs value
  const [value, setValue] = useState(0);
  // Tabs handler
  const handleChange = (_event, newValue) => setValue(newValue);
  // Tabs content props
  const descriptionPanelProps = {
    description: product?.description,
  };
  const reviewsProps = { productSlug: product.slug, reviews: product.reviews, isReviewOpen: isReviewsOpen };
  const technicalParametersPanelProps = { attributes: product?.attributes };

  const matches = useMediaQuery(`(max-width: ${screen_md}px)`);

  const tabsProps = {
    className: "tabInnerWrapper",
    value,
    onChange: handleChange,
    "aria-label": "tabs",
    variant: "scrollable",
    ...(matches && { scrollButtons: true, allowScrollButtonsMobile: true }),
  };

  useEffect(() => {
    if (isReviewsOpen) setValue(1);
  }, [isReviewsOpen]);

  return (
    <MaterialBox className="tabs">
      <MaterialBox className="tabWrapper">
        <Tabs {...tabsProps}>
          {TabsTitles.map((title, i) => (
            <Tab key={i} label={t(title)} id={`content-tab-${i}`} disableRipple={true} />
          ))}
        </Tabs>
      </MaterialBox>

      {TabsPanels.map((Component, i) => (
        <div
          key={i}
          role="tabpanel"
          hidden={value !== i}
          id={`content-tabpanel-${i}`}
          aria-labelledby={`content-tab-${i}`}
        >
          <MaterialBox>
            <Component
              {...(i === 0 && descriptionPanelProps)}
              {...(i === 1 && reviewsProps)}
              {...(i === 2 && technicalParametersPanelProps)}
            />
          </MaterialBox>
        </div>
      ))}
    </MaterialBox>
  );
}
