import React from "react";
import PasswordChange from "./PasswordChange";

export default function UserAccountChangePw() {
  return (
    <div className="user-profile">
      <PasswordChange />
    </div>
  );
}
