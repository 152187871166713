import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { BillingAndDeliveryAddress, Loader } from "components";
import EshopContext from "context/eshop/eshopContext";
import useIsFormDirty from "hooks/useIsFormDirty";
import { checkClickAndKeyPress } from "helpers";
import { storeUserData } from "utils";

export default function UserAccountAddresses() {
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const { dispatchEshop } = useContext(EshopContext);

  const [editMode, setEditMode] = useState(false);

  const setEditModeHandler = (e) =>
    checkClickAndKeyPress(e, () => {
      e.preventDefault();
      setEditMode((editted) => !editted);
    });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, dirtyFields },
  } = useForm();

  useIsFormDirty(dirtyFields);

  const onSubmit = async (data) => {
    window.scrollTo(0, 0);
    storeUserData({ data, dispatch, dispatchEshop, setEditMode, reset });
  };

  const formCss = !editMode && { className: "disabled" };

  return (
    <div className="user-profile-addresses">
      <form onSubmit={handleSubmit(onSubmit)} {...formCss}>
        {isLoading ? (
          <Loader />
        ) : (
          <BillingAndDeliveryAddress register={register} errors={errors} reset={reset} watch={watch} />
        )}

        <div className="form-btns">
          {editMode ? (
            <>
              <button type="submit">{t("save")}</button>
              <button onClick={setEditModeHandler} onKeyDown={setEditModeHandler}>
                {t("close")}
              </button>
            </>
          ) : (
            <button onClick={setEditModeHandler} onKeyDown={setEditModeHandler}>
              {t("edit")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
