import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import { setToast, unsetToast } from "context/app/appActions";
import { USER_WAS_LOGGED_IN_DATA } from "data/constants";
import { localStorageRemoveItem } from "helpers";
import { setToastLogout } from "utils/toast";
import { Xmark } from "public/svg";

const TIME_LIMIT = 7_000;

export default function Toast() {
  const { t } = useTranslation();
  const { isShowed, type, message } = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  const toastType = type === "success" ? " success" : type === "error" ? " error" : "";
  const toastCss = `toast${isShowed ? " show" : ""}${toastType}`;

  const closeToastHandler = () => dispatch(unsetToast());

  useEffect(() => {
    if (USER_WAS_LOGGED_IN_DATA in localStorage) {
      dispatch(setToast(setToastLogout()));
      localStorageRemoveItem(USER_WAS_LOGGED_IN_DATA);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer;
    if (isShowed) timer = setTimeout(() => closeToastHandler(), TIME_LIMIT);
    return () => timer && clearTimeout(timer);
    // eslint-disable-next-line
  }, [isShowed]);

  return (
    <div className={toastCss}>
      <div className="toastContent">
        <p dangerouslySetInnerHTML={{ __html: t(message) }}></p>

        <span className="close" onClick={closeToastHandler} role="button" aria-label="close">
          <Xmark />
        </span>
      </div>
    </div>
  );
}
