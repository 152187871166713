import React from "react";
import { useTranslation } from "next-i18next";
import TableItem from "./TableItem";

export default function Table({ data }) {
  const { t } = useTranslation();

  return (
    <section className="table">
      <table>
        <thead>
          <tr>
            <th>{t("order-no")}</th>
            <th>
              <label>{t("total")}</label>
            </th>
            <th>
              <label>{t("currency")}</label>
            </th>
            <th>
              <label>{t("payment_method")}</label>
            </th>
            <th>
              <label>{t("status")}</label>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <TableItem key={item.id} tableData={item} />
          ))}
        </tbody>
      </table>
    </section>
  );
}
