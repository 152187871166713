import React from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import { checkClickAndKeyPress } from "helpers";

export default function TabsNavigation({ items, onItemClick, selected }) {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const switchTabHandler = (e, item, index) =>
    checkClickAndKeyPress(e, () => {
      e.preventDefault();
      if (typeof onItemClick !== "undefined") {
        onItemClick(item, index);
      }
    });

  return (
    <nav className="tabs-navigation">
      <ul>
        {items.map((menuItem, i) => {
          const isActive = selected === i;
          return (
            <li key={i} {...(isActive && { className: "active" })}>
              <Link
                href={pathname}
                onClick={(e) => switchTabHandler(e, menuItem, i)}
                onKeyDown={(e) => switchTabHandler(e, menuItem, i)}
              >
                {t(menuItem.name)}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
