import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import Link from "next/link";
import useIsFormDirty from "hooks/useIsFormDirty";
import { forgottenUserPassword } from "utils";
import { LOGIN_URL } from "data/constants";
import { Loader } from "components";
import Input from "./input";

const defaultValues = { email: "" };

export default function ForgottenPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({ defaultValues });

  useIsFormDirty(dirtyFields);

  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    reset(defaultValues);
    forgottenUserPassword({ data, setSuccess, dispatch });
  };

  return (
    <div className="form user-login">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">{t("forgotten-password?")}</h1>
        <p className="info-line">{t("forgotten-password-info")}</p>

        {!isLoading && !success && (
          <>
            <fieldset>
              <div className="formRow">
                <Input
                  type="email"
                  inputProps={{ register, errors }}
                  inputData={{ name: "email", placeholder: "Email", message: "empty-email" }}
                />
              </div>
            </fieldset>

            <input type="submit" value={t("send")} />
          </>
        )}

        {isLoading && <Loader />}

        {success && (
          <div className="form-actions">
            <p>
              {t("go-to-login")} <br />
              <Link href={LOGIN_URL}>
                {" "}
                <strong>{t("login")}</strong>
              </Link>
            </p>
          </div>
        )}
      </form>
    </div>
  );
}
