import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { PROFILE_URL, USER_TABS } from "data/constants";

export default function useTabsNavigation() {
  const router = useRouter();

  const [selectedTab, setSelectedTab] = useState(router?.query?.tab ?? "profile");
  const selectedTabIndex = USER_TABS.map(({ ident }) => ident).indexOf(selectedTab);

  const setTab = (name) => router.push({ pathname: PROFILE_URL, query: { tab: name } });

  const onTabClick = (item) => {
    setSelectedTab(item.ident);
    setTab(item.ident);
  };

  useEffect(() => {
    if (router?.query?.tab) {
      setSelectedTab(router.query.tab);
    }
  }, [router?.query]);

  return { tabs: USER_TABS, selectedTab, selectedTabIndex, onTabClick };
}
