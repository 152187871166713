import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import Link from "next/link";
import {
  createBreadcrumbName,
  createBreadcrumbHref,
  createLinkPath,
  generateProductPathname,
  isArrayDefined,
} from "helpers";
import { CATEGORY_PAGE, FOCUS_PAGE } from "data/constants";
import { House } from "public/svg";

export default function BreadCrumbs() {
  const { t } = useTranslation();
  const router = useRouter();
  const pageParams = useSelector((state) => state.pageParams);
  const productPathNames = useSelector((state) => state.productPathNames);
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  useEffect(() => {
    if (router) {
      const linkPath = createLinkPath(router.asPath);
      linkPath.shift();

      const [rootCategory] = linkPath;
      let isRootCategoryMain = false;
      if ((rootCategory === FOCUS_PAGE || rootCategory === CATEGORY_PAGE) && linkPath.length > 1) {
        isRootCategoryMain = true;
      }

      const pathArray = linkPath.map((path, i) => ({
        breadcrumb: createBreadcrumbName(path, isRootCategoryMain),
        href: createBreadcrumbHref(i, pageParams, path, linkPath),
      }));

      if (router.pathname.indexOf(CATEGORY_PAGE) > -1 || router.pathname.indexOf(FOCUS_PAGE) > -1) {
        pathArray.shift();
      }
      if (router.pathname.indexOf("/alles") > -1) {
        pathArray.pop();
      }
      if (router.pathname.indexOf("/product/") > -1 && isArrayDefined(productPathNames)) {
        generateProductPathname(pathArray, productPathNames);
      }

      setBreadcrumbs([{ breadcrumb: t("Home"), href: "/" }, ...pathArray]);
    }
  }, [router, pageParams, productPathNames, t]);

  if (!breadcrumbs) return null;

  return (
    <div className="breadcrumbs">
      {breadcrumbs.length > 0 ? (
        <ul className="breadcrumbsList" aria-label="breadcrumb">
          {breadcrumbs.map((breadItem, i) =>
            i === 0 ? (
              <li key={i} className="breadcrumbsListItem">
                <Link href="/" className="listItemLink" aria-label={t("home")}>
                  <House />
                </Link>
              </li>
            ) : (
              <li key={i} className="breadcrumbsListItem">
                {breadItem.href ? (
                  <Link href={breadItem.href} className="listItemLink">
                    <span>{breadItem.breadcrumb}</span>
                  </Link>
                ) : (
                  <span>{breadItem.breadcrumb}</span>
                )}
              </li>
            )
          )}
        </ul>
      ) : (
        <ul className="breadcrumbs">
          <li>
            <span>&nbsp;</span>
          </li>
        </ul>
      )}
    </div>
  );
}
