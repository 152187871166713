import React from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { checkClickAndKeyPress } from "helpers";

export default function TableItem({ tableData }) {
  const { t } = useTranslation();
  const router = useRouter();

  const orderDetailHandler = (e) =>
    checkClickAndKeyPress(e, () =>
      router.push({ pathname: `order/${tableData.id}`, query: { tab: "orders" } })
    );

  const customerNote = tableData?.meta_data?.find(({ key }) => key === "customer_note")?.value;

  return (
    <>
      <tr>
        <td>
          {t("order-no")} {tableData.number}
        </td>
        <td>{tableData.total}</td>
        <td>{tableData.currency}</td>
        <td>{tableData.payment_method_title}</td>
        <td>{tableData.status}</td>
        <td>
          <button onClick={orderDetailHandler} onKeyDown={orderDetailHandler}>
            {t("order-detail")}
          </button>
        </td>
      </tr>
      {customerNote && (
        <tr key={"note" + tableData.id}>
          <td colSpan={6}>{customerNote}</td>
        </tr>
      )}
    </>
  );
}
