import React from "react";
import { useTranslation } from "next-i18next";
import { CatalogueListItem } from "components";

export default function SimiliarProducts({ products }) {
  const { t } = useTranslation();

  return (
    <section className="similarProducts">
      <h2>{t("similiar-products")}:</h2>
      <div className="line">
        {products?.map((item, i) => (
          <CatalogueListItem key={i} row={item} />
        ))}
      </div>
    </section>
  );
}
