import React, { useState } from "react";
import { useTranslation } from "next-i18next";
import { InputErrorMessage } from "components";

export default function Password({ register, errors, inputData }) {
  const { t } = useTranslation();

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const onChangeHandler = () => setIsPasswordShown((isShowed) => !isShowed);

  const passwordProps = {
    ...register(inputData?.name ? inputData.name : "password", {
      required: { value: true, message: t("empty-password") },
      minLength: {
        value: 6,
        message: t("info-password"),
      },
    }),
    placeholder: inputData?.placeholder ? t(inputData.placeholder) : t("password"),
    type: isPasswordShown ? "text" : "password",
  };

  return (
    <>
      <div className="password">
        <input {...passwordProps} />
        <i onClick={onChangeHandler} />
      </div>
      <InputErrorMessage errors={errors} name={inputData?.name ? inputData.name : "password"} />
    </>
  );
}
