import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import EshopContext from "context/eshop/eshopContext";
import Rating from "../../rating/Rating";
import { getCurrentDate } from "helpers";
import { Input } from "components";

export default function NewReview({ onSubmit, register, errors, setRating }) {
  const {
    state: {
      user: { isLoggedIn, userInfo },
    },
  } = useContext(EshopContext);

  const { t } = useTranslation();

  return (
    <div className="reviewsRow">
      <form onSubmit={onSubmit}>
        <div className="reviewsRowHead">
          <div className={`reviewsRowHeadLeft${isLoggedIn ? "" : " flex"}`}>
            {isLoggedIn ? (
              <strong>{userInfo.name}</strong>
            ) : (
              <>
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "reviewer",
                    placeholder: "name",
                    message: "empty-name",
                    autofocus: !isLoggedIn,
                  }}
                />

                <Input
                  type="email"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "reviewer_email",
                    message: "empty-email",
                  }}
                />
              </>
            )}
            {` ${t("on")} ${getCurrentDate()}`}
          </div>
          <div className="reviewsRowHeadRight">
            <Rating setRating={setRating} />
          </div>
        </div>

        <div className="reviewsRowContent">
          <Input
            type="textarea"
            inputProps={{ register, errors }}
            inputData={{ name: "review", message: "empty-review", autoFocus: isLoggedIn }}
          />
        </div>

        <input type="submit" value={t("send")} />
      </form>
    </div>
  );
}
