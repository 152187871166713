import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import { setElementSizesBreadcrumbs } from "context/app/appActions";
import { SQUARE_BRACKETS_REGEX } from "helpers";
import { House } from "public/svg";

export default function BreadCrumbs() {
  const { t } = useTranslation();
  const router = useRouter();
  const pageParams = useSelector((state) => state.pageParams);
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  const elementSizes = useSelector((state) => state.elementSizes);
  const dispatch = useDispatch();
  const [breadcrumbsRef, setBreadcrumbsRef] = useState(null);

  const breadcrumbsRefHandler = useCallback((node) => {
    if (node !== null) setBreadcrumbsRef(node);
  }, []);

  const onResize = (node) => {
    dispatch(setElementSizesBreadcrumbs(node[0].target.clientHeight));
  };

  useEffect(() => {
    if (!breadcrumbsRef) return;

    const resizeObserver = new ResizeObserver((node) => {
      onResize(node);
    });

    resizeObserver.observe(breadcrumbsRef);

    return () => resizeObserver.disconnect();
    // eslint-disable-next-line
  }, [breadcrumbsRef]);

  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.replace(SQUARE_BRACKETS_REGEX, "").split("/");
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => ({
        breadcrumb: path.replace(/\?.*$/g, ""),
        href: i === 1 && pageParams ? pageParams : "/" + linkPath.slice(0, i + 1).join("/"),
      }));

      if (router.pathname.includes("/uber-koh-i-noor") && pathArray.length >= 2) {
        delete pathArray[1].href;
      } else {
        pathArray.forEach((item) => delete item.href);
      }

      setBreadcrumbs([{ breadcrumb: t("Home"), href: "/" }, ...pathArray]);
    }
    // eslint-disable-next-line
  }, [router, pageParams]);

  if (!breadcrumbs) return null;

  return (
    <section className="breadCrumbsWithSort">
      <div className="breadcrumbs" style={{ top: `${elementSizes.header}px` }} ref={breadcrumbsRefHandler}>
        {breadcrumbs.length > 0 ? (
          <ul className="breadcrumbsList" aria-label="breadcrumb">
            {breadcrumbs.map((breadItem, i) =>
              i === 0 ? (
                <li key={i} className="breadcrumbsListItem">
                  <Link href="/" className="listItemLink" title={breadItem.breadcrumb}>
                    <House />
                  </Link>
                </li>
              ) : (
                <li key={i} className="breadcrumbsListItem">
                  {breadItem.href ? (
                    <Link href={breadItem.href} className="listItemLink" title={breadItem.breadcrumb}>
                      <span>{breadItem.breadcrumb}</span>
                    </Link>
                  ) : (
                    <span title={breadItem.breadcrumb}>{breadItem.breadcrumb}</span>
                  )}
                </li>
              )
            )}
          </ul>
        ) : (
          <ul className="breadcrumbs">
            <li>
              <span>&nbsp;</span>
            </li>
          </ul>
        )}
      </div>
    </section>
  );
}
