import React, { useRef, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { CATEGORIES, CATEGORY_PAGE, FOCUS_PAGE } from "data/constants";
import { checkClickAndKeyPress, hasOwn, isArray } from "helpers";

const DynamicVideoModal = dynamic(() => import("./VideoModal"), {
  loading: () => <p>Loading...</p>,
});

const disabledCategory = CATEGORIES[3].name;
const categories = Object.values(CATEGORIES).map(({ name }) => name);

export default function Banner({ title, text }) {
  const { query } = useRouter();

  const getTitleAndDisabledCategory = () => {
    const isCategory = hasOwn(query, CATEGORY_PAGE) && isArray(query[CATEGORY_PAGE]);
    if (isCategory) {
      const queryPathname = query[isCategory ? CATEGORY_PAGE : FOCUS_PAGE]?.[0] ?? "";
      const categoryName = queryPathname?.charAt(0)?.toUpperCase() + queryPathname?.slice(1);
      const isDisabledCategory = categoryName === disabledCategory;
      const bannerTitle = categoryName;
      return { bannerTitle, isDisabledCategory };
    }

    const isFocus = hasOwn(query, FOCUS_PAGE) && isArray(query[FOCUS_PAGE]);
    return {
      bannerTitle: title,
      isDisabledCategory: isFocus ? true : categories.includes(title) ? title === disabledCategory : true,
    };
  };

  const { bannerTitle, isDisabledCategory } = getTitleAndDisabledCategory();

  // Video
  const videoRef = useRef();
  const playVideoHandler = () => videoRef.current.play();
  const stopVideoHandler = () => videoRef.current.pause();

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    stopVideoHandler();
  };
  const handleClose = () => setOpen(false);
  const onClickAndKeyPressHandler = (e) => checkClickAndKeyPress(e, handleOpen);

  // Banner
  const bannerCss = isDisabledCategory ? `disabled ${disabledCategory}` : bannerTitle;
  const bannerProps = !isDisabledCategory && {
    onMouseEnter: playVideoHandler,
    onMouseLeave: stopVideoHandler,
    onClick: onClickAndKeyPressHandler,
    onKeyDown: onClickAndKeyPressHandler,
    tabIndex: 0,
  };

  return (
    <>
      {!isDisabledCategory && (
        <DynamicVideoModal isOpen={open} close={handleClose} videoTitle={bannerTitle} />
      )}
      <div className={`banner ${bannerCss}`} {...bannerProps}>
        {!isDisabledCategory && (
          <video width="100%" ref={videoRef} preload="auto" muted controls={false} loop playsInline>
            <source src={`/videos/${bannerTitle}.mp4`} type="video/mp4" />
          </video>
        )}
        <div className="bannerInner">
          <h1>{bannerTitle}</h1>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
}
