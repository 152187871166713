import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import { setUserOrderPayment } from "context/eshop/eshopActions";
import EshopContext from "context/eshop/eshopContext";
import { generatePaymentTitle } from "helpers";

export default function PaymentMethod({ payment, formData: { register } }) {
  const { id, method_title, method_description, description, enabled } = payment;

  const { t } = useTranslation();

  const { dispatchEshop } = useContext(EshopContext);

  const onChangePaymentHandler = () => dispatchEshop(setUserOrderPayment(payment));

  return (
    <div className={`payment-option ${!enabled ? "disabled" : "enabled"}`}>
      <div className="payment-button">
        <input
          {...register("payment", {
            required: { value: true, message: t("errPayment") },
          })}
          type="radio"
          id={`payment_${id}`}
          value={id}
          disabled={!enabled}
          onChange={onChangePaymentHandler}
        />
        <label htmlFor={`payment_${id}`}>{generatePaymentTitle(method_title)}</label>
      </div>

      {method_description && (
        <div className="payment-description">
          <p>{method_description}</p>
        </div>
      )}
      {description && (
        <div className="payment-description">
          <p>{description}</p>
        </div>
      )}
    </div>
  );
}
