import React from "react";
import { useTranslation } from "next-i18next";

export default function TechnicalParametersPanel({ attributes }) {
  const { t } = useTranslation();

  const weight = attributes?.find(({ name }) => name === "Gewicht")?.options?.[0];
  const length = attributes?.find(({ name }) => name === "Laenge")?.options?.[0];
  const width = attributes?.find(({ name }) => name === "Breite")?.options?.[0];
  const height = attributes?.find(({ name }) => name === "Hoehe")?.options?.[0];
  const content = attributes?.find(({ name }) => name === "VE - Inhalt")?.options?.[0];

  return (
    <section className="table">
      <table>
        <tbody>
          <tr>
            <td>{t("weight")}:</td>
            <td>{weight} kg</td>
          </tr>
          <tr>
            <td>{t("contents")}:</td>
            <td>
              {content} {t("piece")}
            </td>
          </tr>
          <tr>
            <td>
              {t("dimensions")} ( {t("length")} &times; {t("width")} &times; {t("height")} ):
            </td>
            <td>
              {length} &times; {width} &times; {height} {t("unit-of-measure-cm")}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}
