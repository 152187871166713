import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { setCookies, getCookie } from "cookies-next";
import { checkClickAndKeyPress } from "helpers";

const eventType = ({ type, key }) => type === "click" || key === "Enter" || key === " ";

export default function CookieConsent() {
  const { t } = useTranslation();

  const [agreed, setAgreed] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const agreed = getCookie("agreed");
    if (!agreed) {
      setVisible(true);
    } else {
      setAgreed(agreed);
    }
  }, []);

  const okHandler = (event) =>
    checkClickAndKeyPress(event, () => {
      event.preventDefault();
      if (eventType(event)) {
        setCookies("agreed", "1");
        setAgreed("1");
      }
    });

  const koHandler = (event) =>
    checkClickAndKeyPress(event, () => {
      event.preventDefault();
      if (eventType(event)) {
        setCookies("agreed", "0");
        setAgreed("0");
      }
    });

  if (agreed) return null;

  return (
    <div className={`cookieConsent${visible ? " visible" : ""}`}>
      <article>
        <h4>{t("gdpr-h")}</h4>
        <p>{t("gdpr-p1")}</p>
        <p>{t("gdpr-p2")}</p>
      </article>
      <div className="cookieConsentButtons">
        <button onClick={okHandler} onKeyDown={okHandler}>
          {t("gdpr-accept")}
        </button>
        <button onClick={koHandler} onKeyDown={koHandler}>
          {t("gdpr-decline")}
        </button>
      </div>
    </div>
  );
}
