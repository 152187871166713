import { useEffect, useRef } from "react";

export default function useAnimateQuantity(quantity) {
  const quantityRef = useRef(quantity);
  const amountRef = useRef(null);

  useEffect(() => {
    let timer;
    if (amountRef?.current) {
      if (quantityRef.current !== quantity) {
        quantityRef.current = quantity;
        amountRef.current.classList.add("animated");
        setTimeout(() => amountRef.current.classList.remove("animated"), 250);
      }
    }

    return () => timer && clearTimeout(timer);
  }, [quantity]);

  return amountRef;
}
