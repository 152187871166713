import React from "react";
import { useTranslation } from "next-i18next";
import { InputErrorMessage } from "components";

export default function Input({
  inputData: { name, placeholder, message, isRequired = true, readOnly = false, autofocus = false },
  register,
  errors,
}) {
  const { t } = useTranslation();

  const inputProps = {
    ...register(name, {
      required: { value: isRequired, message: message ? t(message) : "" },
    }),
    placeholder: t(placeholder),
    readOnly: readOnly,
    type: "text",
    autoFocus: autofocus,
  };

  return (
    <>
      <input {...inputProps} />
      <InputErrorMessage errors={errors} name={name} />
    </>
  );
}
