import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { changeCartItemAmount, removeItemFromCart } from "context/eshop/eshopActions";
import { checkClickAndKeyPress, createProductLink, formatPrice, getFormatedPrice } from "helpers";
import { MinusCircle, PlusCircle, TrashCircle } from "public/svg";
import EshopContext from "context/eshop/eshopContext";
import { Image } from "components";

export default function BasketItemsRow({ cartItem }) {
  const { t } = useTranslation();

  const { id, amount, image, name, price, slug } = cartItem;

  const { dispatchEshop } = useContext(EshopContext);

  const decreaseCartAmount = () => dispatchEshop(changeCartItemAmount({ id, amount, type: "REMOVE" }));
  const decreaseAmountHandler = (e) => checkClickAndKeyPress(e, decreaseCartAmount);

  const increaseCartAmount = () => dispatchEshop(changeCartItemAmount({ id, amount, type: "ADD" }));
  const increaseAmountHandler = (e) => checkClickAndKeyPress(e, increaseCartAmount);

  const quantityHandler = (event) =>
    dispatchEshop(changeCartItemAmount({ id, amount: event.target.value, type: "SET" }));

  const removeItemHandler = (e) =>
    checkClickAndKeyPress(e, () => dispatchEshop(removeItemFromCart(cartItem.id)));

  const productLink = createProductLink(slug);

  return (
    <div className="basketItem">
      <div className="td image">
        <figure>
          <Link href={productLink}>
            <Image src={image?.src} alt={image?.name} css="contain" loading="eager" />
          </Link>
        </figure>
      </div>
      <div className="td name">
        <Link href={productLink}>{name}</Link>
      </div>
      <div className="td price">
        <span className="hint">{t("price")}</span>
        {formatPrice(cartItem.price)}
      </div>
      <div className="td count">
        <span className="hint">{t("quantity")}</span>
        <div className="quantity sm">
          <button
            onClick={decreaseAmountHandler}
            onKeyDown={decreaseAmountHandler}
            className={`decrease${amount <= 1 ? " disabled" : ""}`}
            role="button"
            title={t("decrease")}
            aria-label={t("decrease")}
          >
            <MinusCircle />
          </button>
          <input
            type="numer"
            name="q"
            value={amount}
            min="1"
            autoComplete="off"
            aria-label={t("quantity")}
            onChange={quantityHandler}
          />
          <button
            onClick={increaseAmountHandler}
            onKeyDown={increaseAmountHandler}
            className="increase"
            role="button"
            title={t("increase")}
            aria-label={t("increase")}
          >
            <PlusCircle />
          </button>
        </div>
      </div>
      <div className="td sub-total">
        <span className="hint">{t("sum")}</span>
        {getFormatedPrice(amount * price)}
      </div>
      <div className="td actions">
        <button
          onClick={removeItemHandler}
          onKeyDown={removeItemHandler}
          role="button"
          aria-label={t("remove")}
        >
          <TrashCircle />
        </button>
      </div>
    </div>
  );
}
