import { checkClickAndKeyPress } from "helpers";
import React from "react";
import InfoWindow from "./InfoWindow";

export default function Marker({ dealer, onClick, onClose, showInfo }) {
  const expandHandler = (e) =>
    checkClickAndKeyPress(e, () => {
      e.preventDefault();
      e.stopPropagation();
      onClick(dealer);
    });

  return (
    <>
      <div
        className={`marker${showInfo ? " expanded" : ""}`}
        onClick={expandHandler}
        onKeyDown={expandHandler}
      />
      {showInfo && <InfoWindow dealer={dealer} onClose={onClose} />}
    </>
  );
}
