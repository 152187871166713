import React, { useContext } from "react";
import { useSelector } from "react-redux";
import {
  UserAccountChangePassword,
  UserAccountAddresses,
  UserAccountOrders,
  TabsNavigation,
  ContinueLogin,
  Loader,
} from "components";
import useTabsNavigation from "hooks/useTabsNavigation";
import EshopContext from "context/eshop/eshopContext";
import UserAccountLogout from "./UserAccountLogout";

export default function UserAccount() {
  const isLoading = useSelector((state) => state.isLoading);
  const {
    state: {
      user: { isLoggedIn },
    },
  } = useContext(EshopContext);

  // tabs
  const { tabs, selectedTab, selectedTabIndex, onTabClick } = useTabsNavigation();

  return (
    <div className="user-profile">
      {isLoggedIn ? (
        <>
          <TabsNavigation items={tabs} onItemClick={onTabClick} selected={selectedTabIndex} />

          {selectedTab === "profile" && <UserAccountAddresses />}
          {selectedTab === "orders" && <UserAccountOrders />}
          {selectedTab === "password" && <UserAccountChangePassword />}
          {selectedTab === "logout" && <UserAccountLogout />}
        </>
      ) : isLoading ? (
        <Loader />
      ) : (
        <div className="flex">
          <div className="illustration" />
          <ContinueLogin />
        </div>
      )}
    </div>
  );
}
