import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { setFiltersPrice, setFiltersPriceMax, setFiltersPriceMin } from "context/app/appActions";
import { PRICE_MIN, PRICE_MAX } from "data/constants";
import { debounce } from "helpers";

const DynamicSlider = dynamic(() => import("@mui/material/Slider"), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});

const valuetext = (value) => `${value} ${String.fromCharCode("8364")}`;

export default function PriceFilter() {
  const price = useSelector((state) => state.filters.price);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const setValue = (newValue) => dispatch(setFiltersPrice(newValue));
  const handleChange = (_event, newValue) => debounce(setValue(newValue));

  const setMin = (e) => dispatch(setFiltersPriceMin(Number(e.target.value)));
  const onChangeMin = (e) => debounce(setMin(e));

  const setMax = (e) => dispatch(setFiltersPriceMax(Number(e.target.value)));
  const onChangeMax = (e) => debounce(setMax(e));

  return (
    <div className="controlsRow">
      <h3>{t("price")}</h3>
      <div className="priceSlider">
        <DynamicSlider
          value={price}
          min={PRICE_MIN}
          max={PRICE_MAX}
          onChange={handleChange}
          valueLabelDisplay="auto"
          valueLabelFormat={valuetext}
          getAriaLabel={() => "priceSliderRange"}
        />
      </div>
      <div className="priceFilter">
        <input type="text" placeholder={PRICE_MIN} defaultValue={price[0]} onChange={onChangeMin} />
        <input type="text" placeholder={PRICE_MAX} defaultValue={price[1]} onChange={onChangeMax} />
      </div>
    </div>
  );
}
