import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { setElementSizesBreadcrumbsWithSort } from "context/app/appActions";
import CatalogueSortFilters from "./catalogue/CatalogueSortFilters";
import useResizeObserver from "hooks/useResizeObserver";
import BreadCrumbs from "./BreadCrumbs";

export default function BreadCrumbsWithSort({ hideSort = false, enableHide = false, isHidden }) {
  const dispatch = useDispatch();
  const breadcrumbWithSortRef = useRef();

  const onResize = (node) => dispatch(setElementSizesBreadcrumbsWithSort(node[0].target.clientHeight));

  useResizeObserver({ ref: breadcrumbWithSortRef, onResize });

  const breadCrumbsWithSortCss = `breadCrumbsWithSort${enableHide ? " hidden" : ""}${
    enableHide && isHidden ? " show" : ""
  }`;

  return (
    <section className={breadCrumbsWithSortCss} ref={breadcrumbWithSortRef}>
      <BreadCrumbs />
      {!hideSort && <CatalogueSortFilters />}
    </section>
  );
}
