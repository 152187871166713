import React from "react";
import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { Image } from "components";
import { checkClickAndKeyPress } from "helpers";

export default function PhotoSlider({ imgs, selectedImg, selectImage }) {
  const swiperProps = {
    modules: [Scrollbar],
    spaceBetween: 20,
    slidesPerView: 4,
    autoHeight: false,
    slideToClickedSlide: true,
    grabCursor: true,
    loop: imgs.length > 4,
    autoplay: false,
    scrollbar: {
      hide: true,
      snapOnRelease: true,
    },
  };

  const selectPhotoHandler = (e, i) => checkClickAndKeyPress(e, () => selectImage(i));

  return (
    <div className="productPhotoSlider">
      <Swiper {...swiperProps}>
        {imgs?.map((item, i) => (
          <SwiperSlide key={i}>
            <div
              className={`sliderPhoto${selectedImg.src === item.src ? " selected" : ""}`}
              onClick={(e) => selectPhotoHandler(e, i)}
              onKeyDown={(e) => selectPhotoHandler(e, i)}
              tabIndex="0"
            >
              <Image src={item?.src} alt={item?.name} css="contain" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
