import { ErrorMessage } from "@hookform/error-message";

export default function InputErrorMessage({ errors, name }) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      as={<div className="e"></div>}
    />
  )
}
