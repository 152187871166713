import React from "react";
import { useTranslation } from "next-i18next";
import BasketItemsRow from "./BasketItemsRow";

export default function BasketItems({ cartItems }) {
  const { t } = useTranslation();

  return (
    <div className="basketItems">
      <div className="basketItemsHeader">
        <div className="td image">{t("name")}</div>
        <div className="td name">&nbsp;</div>
        <div className="td price">{t("price")}</div>
        <div className="td count">{t("quantity")}</div>
        <div className="td sub-total">{t("total")}</div>
        <div className="td actions">&nbsp;</div>
      </div>

      {cartItems.map((cartItem) => (
        <BasketItemsRow key={cartItem.id} cartItem={cartItem} />
      ))}
    </div>
  );
}
