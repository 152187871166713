import React from "react";
import { useTranslation } from "next-i18next";
import { InputErrorMessage } from "components";

export default function TextArea({
  inputData: { name, isRequired = true, message = "", autoFocus = false },
  register,
  errors,
}) {
  const { t } = useTranslation();

  const onInput = ({ target }) => (target.style.height = `${target.scrollHeight}px`);

  const textAreaProps = {
    ...register(name, { required: { value: isRequired, message: message ? t(message) : t("empty-text") } }),
    onInput,
    autoFocus,
    "aria-label": "text",
  };

  return (
    <>
      <textarea {...textAreaProps} />
      {errors && <InputErrorMessage errors={errors} name={name} />}
    </>
  );
}
