import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import Link from "next/link";
import useSubscribeToNewsletter from "hooks/useSubscribeToNewsletter";
import { LOGIN_URL, LOST_PW_URL } from "data/constants";
import { InputErrorMessage, Loader } from "components";
import { setIsDirty } from "context/app/appActions";
import { CheckmarkCircle } from "public/svg/index";
import useIsFormDirty from "hooks/useIsFormDirty";
import { registerUser } from "utils";
import Input from "./input";

const defaultValues = { name: "", email: "", password: "", passwordConfirm: "", agree: "", newsletter: "" };

export default function Registration() {
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues,
  });

  useIsFormDirty(dirtyFields);

  const [registerState, setRegisterState] = useState(false);

  const subscribeToNewsletter = useSubscribeToNewsletter();

  const onSubmit = (data) => {
    dispatch(setIsDirty(false));
    // Newsletter subscribe
    if (data.newsletter) {
      subscribeToNewsletter({ email: data?.email });
    }

    // Register
    registerUser({ data, setRegisterState, dispatch });
  };

  return (
    <div className="form user-registration">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">{t("registration")}</h1>

        {!isLoading && !registerState && (
          <>
            <fieldset>
              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{ name: "name", placeholder: "name", message: "empty-name" }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="email"
                  inputProps={{ register, errors }}
                  inputData={{ name: "email", placeholder: "Email", message: "empty-email" }}
                />
              </div>

              <div className="formRow">
                <Input type="password" inputProps={{ register, errors }} />
              </div>

              <div className="formRow">
                <Input type="password-confirm" inputProps={{ register, errors, getValues }} />
              </div>

              <div className="formRow">
                <Input
                  type="checkbox"
                  inputProps={{ register, errors }}
                  inputData={{ name: "agree", id: "i-agree", message: "error-agree-registration" }}
                />

                <label htmlFor="i-agree">
                  {t("agree-with")}{" "}
                  <Link target="_blank" href="/info-hilfe/datenschulzerklarung" rel="noopener noreferrer">
                    {t("personal-data-processing")} ({t("eshop")})
                  </Link>
                </label>

                <InputErrorMessage errors={errors} name="agree" />
              </div>

              <div className="formRow">
                <Input
                  type="checkbox"
                  inputProps={{ register, errors }}
                  inputData={{ name: "newsletter", id: "i-newsletter", isRequired: false }}
                />

                <label htmlFor="i-newsletter">{t("agree-newsletter-registration")}</label>
              </div>
            </fieldset>
            <input type="submit" value={t("register")} />
          </>
        )}
        {isLoading && <Loader />}
        {!isLoading && registerState && (
          <>
            <CheckmarkCircle />
            <div>
              <p>
                <Link className="button" href={LOGIN_URL}>
                  <strong>{t("continue-to-login")}</strong>
                </Link>
              </p>
            </div>
          </>
        )}
      </form>

      {!isLoading && !registerState && (
        <div className="actions">
          <p>
            {t("alredy-registered")}{" "}
            <Link href={t(LOGIN_URL)}>
              <strong>{t("login-here")}</strong>
            </Link>
          </p>
          <p>
            {t("forgotten-password?")}{" "}
            <Link href={t(LOST_PW_URL)}>
              <strong>{t("restore-password")}</strong>
            </Link>
          </p>
        </div>
      )}
    </div>
  );
}
