import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import Link from "next/link";
import InputErrorMessage from "./user/input/InputErrorMessage";
import { sendContactForm } from "utils/woocommerce";
import useIsFormDirty from "hooks/useIsFormDirty";
import { Input, Loader } from "components";

const defaultValues = { firstname: "", lastname: "", email: "", phone: "", text: "" };

export default function ContactForm() {
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({ defaultValues });

  useIsFormDirty(dirtyFields);

  const onSubmit = (data) => {
    reset(defaultValues);
    sendContactForm({ data, dispatch });
  };

  return (
    <form id="form" className="contact-form" onSubmit={handleSubmit(onSubmit)}>
      {!isLoading && (
        <>
          <fieldset>
            <div className="formGroup">
              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{ name: "firstname", placeholder: "firstname", message: "empty-firstname" }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{ name: "lastname", placeholder: "surname", message: "empty-surname" }}
                />
              </div>
            </div>

            <div className="formGroup">
              <div className="formRow">
                <Input
                  type="tel"
                  inputProps={{ register, errors }}
                  inputData={{ name: "phone", isRequired: true }}
                />
              </div>

              <div className="formRow">
                <Input type="email" inputProps={{ register, errors }} inputData={{ name: "email" }} />
              </div>
            </div>

            <div className="formRow">
              <Input type="textarea" inputProps={{ register, errors }} inputData={{ name: "text" }} />
            </div>

            <div className="formRow">
              <Input
                type="checkbox"
                inputProps={{ register, errors }}
                inputData={{
                  name: "agree",
                  id: "i-agree",
                  message: t("Bitte stimmen Sie unserer Vereinbarung zu. "),
                }}
              />
              <label htmlFor="i-agree">
                {t("agree-with")}{" "}
                <Link href="/info-hilfe/datenschulzerklarung" target="_blank">
                  Datenschulzerklarung
                </Link>
              </label>

              <InputErrorMessage errors={errors} name="agree" />
            </div>
          </fieldset>

          <input type="submit" value={t("submit")} />
        </>
      )}

      {isLoading && <Loader />}
    </form>
  );
}
