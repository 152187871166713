import React from "react";
import { useTranslation } from "next-i18next";
import { InputErrorMessage } from "components";
import { EMAIL_REGEX } from "helpers";

export default function InputEmail({
  inputData: { name, isRequired = true, readOnly = false, placeholder = "email", autofocus = false },
  register,
  errors,
}) {
  const { t } = useTranslation();

  const inputProps = {
    ...register(name, {
      required: { value: isRequired, message: t("empty-email") },
      pattern: {
        value: EMAIL_REGEX,
        message: t("email-pattern"),
      },
    }),
    placeholder: t(placeholder),
    readOnly: readOnly,
    type: "email",
    autoFocus: autofocus,
  };

  return (
    <>
      <input {...inputProps} />
      {errors && <InputErrorMessage errors={errors} name={name} />}
    </>
  );
}
