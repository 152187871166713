import React, { useEffect, useState, useRef, useCallback } from "react";
import GoogleMap from "google-maps-react-markers";
import useSupercluster from "use-supercluster";
import { useTranslation } from "next-i18next";
import { SearchIcon } from "public/svg";
import mapData from "public/map.json";
import Marker from "./Marker";

const MarkerCluster = ({ children }) => children;

const coordinates = { lat: 50.937668, lng: 10.228044 };

export default function Map() {
  const { t } = useTranslation();

  const mapRef = useRef(null);
  const searchBox = useRef(null);
  const input = useRef(null);

  // const [data, setData] = useState(mapData.data);
  const [userCoordinates, setUserCoordinates] = useState({ lat: 0, lng: 0 });
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [showInfoWindow, setShowInfoWindow] = useState(0);
  const [mapsApi, setMapsApi] = useState(null);
  const [places, setPlaces] = useState([]);

  const points = mapData.data.map((item) => ({
    type: "Feature",
    properties: { cluster: false, crimeId: item.id, dealer: item },
    geometry: {
      type: "Point",
      coordinates: [item.lng, item.lat],
    },
  }));

  const updateCoordsToCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      if (position && position.coords) {
        setUserCoordinates({ lat: position.coords.latitude, lng: position.coords.longitude });
      }
    });
  };

  useEffect(() => {
    updateCoordsToCurrentPosition();
  }, []);

  // pan to user position
  useEffect(() => {
    if (userCoordinates.lat && userCoordinates.lng && mapRef.current) {
      mapRef.current.panTo({ lat: userCoordinates.lat, lng: userCoordinates.lng });
    }
    // eslint-disable-next-line
  }, [userCoordinates, mapRef.current]);

  // Map handlers
  const onMapChange = useCallback(({ zoom, bounds }) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    setZoom(zoom);
    setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
  }, []);

  const onGoogleMapLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapsApi(maps);
    // findCoordinates();
  };

  // Clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const markerClusterClickHandler = (e, clusterId, latitude, longitude) => {
    if (e.type === "click" || e.key === "Enter" || e.key === " ") {
      const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(clusterId), 20);
      mapRef.current.setZoom(expansionZoom);
      mapRef.current.panTo({ lat: latitude, lng: longitude });
    }
  };

  const markerClusterStyle = (pointCount) => ({
    width: `${10 + (pointCount / points.length) * 20}px`,
    height: `${10 + (pointCount / points.length) * 20}px`,
  });

  // Marker and Info window
  const onMarkerClick = (dealer) => {
    setShowInfoWindow(dealer.id);
    mapRef.current.panTo({ lat: dealer.lat, lng: dealer.lng });
  };

  const onInfoWindowClose = () => setShowInfoWindow(0);

  // Search
  const handleOnPlacesChanged = useCallback(() => {
    setPlaces(searchBox.current.getPlaces());
  }, [searchBox]);

  const onSubmit = (e) => e.preventDefault();

  useEffect(() => {
    if (!searchBox.current && mapsApi) {
      searchBox.current = new mapsApi.places.SearchBox(input.current);
      searchBox.current.addListener("places_changed", handleOnPlacesChanged);
      setTimeout(() => input?.current?.focus(), 1500);
    }

    return () => {
      if (mapsApi) {
        searchBox.current = null;
        mapsApi.event.clearInstanceListeners(searchBox);
      }
    };
  }, [mapsApi, handleOnPlacesChanged]);

  useEffect(() => {
    if (places) {
      if (places.length === 0) return;

      let bounds = new mapsApi.LatLngBounds();

      places.forEach(function (place) {
        if (!place.geometry) return;
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      mapRef.current.fitBounds(bounds);
    }
  }, [places, mapsApi]);

  return (
    <section id="map">
      <GoogleMap
        apiKey={process.env.GOOGLE_MAP_KEY}
        defaultCenter={coordinates}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleMapLoaded}
        onChange={onMapChange}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <MarkerCluster key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                <div
                  className="cluster-marker"
                  style={markerClusterStyle(pointCount)}
                  onClick={(e) => markerClusterClickHandler(e, cluster.id, latitude, longitude)}
                  onKeyDown={(e) => markerClusterClickHandler(e, cluster.id, latitude, longitude)}
                >
                  {pointCount}
                </div>
              </MarkerCluster>
            );
          }

          const dealer = cluster.properties.dealer;

          return (
            <Marker
              key={`crime-${cluster.properties.crimeId}`}
              lat={latitude}
              lng={longitude}
              dealer={dealer}
              onClick={onMarkerClick}
              onClose={onInfoWindowClose}
              showInfo={showInfoWindow === dealer.id}
              zIndex={showInfoWindow === dealer.id ? 10 : 0}
            ></Marker>
          );
        })}
      </GoogleMap>

      <div className="wrap">
        <form onSubmit={onSubmit}>
          <div id="map-search">
            <fieldset>
              <input
                type="search"
                id="dealer-search"
                name="location"
                placeholder={t("find-dealer")}
                ref={input}
                defaultValue=""
              />
              <button type="submit" aria-label={t("ccfSearch")}>
                <SearchIcon />
              </button>
            </fieldset>
          </div>
        </form>
      </div>
    </section>
  );
}
