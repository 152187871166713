import React, { useContext, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { resetInvoiceAddress, resetInvoiceAndDeliveryAddress } from "helpers/woocommerce";
import { setUserIsInvoiceSameAsDelivery } from "context/eshop/eshopActions";
import EshopContext from "context/eshop/eshopContext";
import { checkClickAndKeyPress } from "helpers";
import { COUNTRIES } from "data/constants";
import Input from "./input";

export default function BillingAndDeliveryAddress({ register, errors, reset, watch }) {
  const { t } = useTranslation();
  const {
    state: {
      user: { isLoggedIn, isInvoiceSameAsDelivery, storedDeliveryAddress, storedInvoiceAddress },
    },
    dispatchEshop,
  } = useContext(EshopContext);

  const watchSameAsBilling = watch("same_as_billing", isInvoiceSameAsDelivery);

  const onChangeSameAsBillingHandler = (e) =>
    checkClickAndKeyPress(e, () => {
      dispatchEshop(setUserIsInvoiceSameAsDelivery(!watchSameAsBilling));
    });

  useEffect(() => {
    if (storedInvoiceAddress) {
      reset(resetInvoiceAddress(storedInvoiceAddress));
    }

    if (storedInvoiceAddress && storedDeliveryAddress && !isInvoiceSameAsDelivery) {
      reset(resetInvoiceAndDeliveryAddress(storedInvoiceAddress, storedDeliveryAddress));
    }
  }, [storedInvoiceAddress, reset, storedDeliveryAddress, isInvoiceSameAsDelivery]);

  return (
    <>
      <div className="basket-cols">
        <div className="basket-col">
          <div className="form user-billing">
            <h2 className="form-headline">{t("billing-address")}</h2>

            <fieldset>
              <div className="formRow">
                <Input
                  type="email"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "email",
                    placeholder: "Email",
                    message: "empty-email",
                    readOnly: isLoggedIn,
                  }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{ name: "firstname", placeholder: "firstname", message: "empty-firstname" }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{ name: "surname", placeholder: "surname", message: "empty-surname" }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "company",
                    placeholder: "company",
                    isRequired: false,
                    message: "empty-company",
                  }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{ name: "street", placeholder: "street", message: "empty-street" }}
                />
              </div>

              <div className="formGroup">
                <div className="formRow">
                  <Input
                    type="text"
                    inputProps={{ register, errors }}
                    inputData={{ name: "zip", placeholder: "zip-code", message: "empty-zip-code" }}
                  />
                </div>

                <div className="formRow">
                  <Input
                    type="text"
                    inputProps={{ register, errors }}
                    inputData={{ name: "city", placeholder: "city", message: "empty-city" }}
                  />
                </div>
              </div>

              <div className="formRow">
                <Input
                  type="select"
                  inputProps={{ register, errors }}
                  inputData={{ name: "country", options: COUNTRIES }}
                />
              </div>

              <div className="formRow nbp">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "phone",
                    placeholder: "phone",
                    isRequired: false,
                    message: "empty-phone",
                  }}
                />
              </div>
            </fieldset>
          </div>
        </div>

        <div className="basket-col">
          <div className="form user-billing">
            <h2 className="form-headline">{t("delivery-address")}</h2>

            <fieldset className={isInvoiceSameAsDelivery ? "formExtend" : "formExtend extended"}>
              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "delivery_firstname",
                    placeholder: "firstname",
                    message: "empty-firstname",
                    isRequired: !isInvoiceSameAsDelivery,
                  }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "delivery_surname",
                    placeholder: "surname",
                    message: "empty-surname",
                    isRequired: !isInvoiceSameAsDelivery,
                  }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "delivery_company",
                    placeholder: "company",
                    message: "empty-company",
                    isRequired: false,
                  }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "delivery_street",
                    placeholder: "street",
                    message: "empty-street",
                    isRequired: !isInvoiceSameAsDelivery,
                  }}
                />
              </div>

              <div className="formGroup">
                <div className="formRow half">
                  <Input
                    type="text"
                    inputProps={{ register, errors }}
                    inputData={{
                      name: "delivery_zip",
                      placeholder: "zip-code",
                      message: "empty-zip-code",
                      isRequired: !isInvoiceSameAsDelivery,
                    }}
                  />
                </div>

                <div className="formRow half">
                  <Input
                    type="text"
                    inputProps={{ register, errors }}
                    inputData={{
                      name: "delivery_city",
                      placeholder: "city",
                      message: "empty-city",
                      isRequired: !isInvoiceSameAsDelivery,
                    }}
                  />
                </div>
              </div>

              <div className="formRow">
                <Input
                  type="select"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "delivery_country",
                    options: COUNTRIES,
                  }}
                />
              </div>

              <div className="formRow">
                <Input
                  type="text"
                  inputProps={{ register, errors }}
                  inputData={{
                    name: "delivery_phone",
                    placeholder: "phone",
                    message: "empty-phone",
                    isRequired: false,
                  }}
                />
              </div>
            </fieldset>

            <div className="formRow nbp">
              <input
                id="same_as_billing"
                {...register("same_as_billing", { value: isInvoiceSameAsDelivery })}
                type="checkbox"
                onClick={onChangeSameAsBillingHandler}
                onKeyDown={onChangeSameAsBillingHandler}
              />
              <label htmlFor="same_as_billing">{t("same-as-billing")}</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
