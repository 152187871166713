import React from "react";
// import { checkClickAndKeyPress, setFocusHandler } from "helpers";
import { useSelector } from "react-redux";

export default function ContentPanel() {
  const elementSizes = useSelector((state) => state.elementSizes);

  /*  const buttonHandler = (e, id) =>
    checkClickAndKeyPress(e, () => {
      setFocusHandler(e);
      const elementPosition = document.getElementById(id)?.offsetTop;
      if (elementPosition) window.scrollTo(0, elementPosition - 20);
    }); */

  const fromTop = elementSizes.header + elementSizes.breadcrumbs;

  return (
    <div role="menu" className="contentPanel" style={{ top: `${fromTop - 1}px` }}>
      {/*       <div className="contentPanelInner">
        <button
          role="menuitem"
          className="subLink"
          onClick={(e) => buttonHandler(e, "1")}
          onKeyDown={(e) => buttonHandler(e, "1")}
        >
          <span>Header title</span>
        </button>
        <button
          role="menuitem"
          className="subLink"
          onClick={(e) => buttonHandler(e, "2")}
          onKeyDown={(e) => buttonHandler(e, "2")}
        >
          <span>Header title 2</span>
        </button>
        <button
          role="menuitem"
          className="subLink"
          onClick={(e) => buttonHandler(e, "3")}
          onKeyDown={(e) => buttonHandler(e, "3")}
        >
          <span>Header title 3</span>
        </button>
      </div> */}
    </div>
  );
}
