import React from "react";
import { useTranslation } from "next-i18next";
import { InputErrorMessage } from "components";
import { PHONE_REGEX } from "helpers";

export default function InputTel({ inputData: { name, isRequired = false }, register, errors }) {
  const { t } = useTranslation();

  const inputProps = {
    ...register(name, {
      required: { value: isRequired, message: t("empty-phone") },
      pattern: {
        value: PHONE_REGEX,
        message: t("phone-pattern"),
      },
    }),
    placeholder: t("phone"),
    type: "tel",
  };

  return (
    <>
      <input {...inputProps} />
      <InputErrorMessage errors={errors} name={name} />
    </>
  );
}
