import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { setIsSearchExpanded } from "context/app/appActions";
import useMediaQuery from "hooks/useMediaQuery";
import { checkClickAndKeyPress } from "helpers";
import { SearchIcon, Xmark } from "public/svg";
import { windowSizes } from "data/constants";
import { Loader } from "components";

const { screen_sm } = windowSizes;

export default function Search() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const matches = useMediaQuery(`(max-width: ${screen_sm}px)`);

  const isSearchExpanded = useSelector((state) => state.isSearchExpanded);
  const [searched, setSearched] = useState(router.query.value ?? "");
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef();

  const onChangeSearch = (e) => setSearched(e.target.value);

  const requestSearch = () => {
    setIsLoading(true);
    router.push({ pathname: "/search", query: { value: searched } });
  };

  const onRequestSearchHandler = (e) =>
    checkClickAndKeyPress(
      e,
      () => searched.length >= 3 && router?.query?.value !== searched && requestSearch()
    );

  const searchIconHandler = () => {
    if (searched.length >= 3 && searched && router?.query?.value !== searched) {
      requestSearch();
    } else {
      focusInputHandler();
      matches && dispatch(setIsSearchExpanded(true));
    }
  };

  const onClickAndKeypressHandler = (e) => checkClickAndKeyPress(e, searchIconHandler);

  const focusInputHandler = () => inputRef.current?.focus();
  const resetHandler = () => {
    setSearched("");
    matches && dispatch(setIsSearchExpanded(false));
  };

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      inputRef.current?.blur();
    }

    // eslint-disable-next-line
  }, [router.asPath]);

  const isResetShowed = searched.length > 0 || (isSearchExpanded && matches);

  return (
    <div className={`search${isSearchExpanded ? " expanded" : ""}`} tabIndex="0">
      <div className="searchIcon" onClick={onClickAndKeypressHandler} onKeyDown={onClickAndKeypressHandler}>
        <SearchIcon />
      </div>
      <input
        className="searchInput"
        type="text"
        placeholder={t("search-placeholder")}
        onKeyDown={onRequestSearchHandler}
        value={searched}
        onChange={onChangeSearch}
        ref={inputRef}
      />
      <div className={`reset${isResetShowed ? " show" : ""}`} onClick={resetHandler}>
        {isLoading ? <Loader /> : <Xmark />}
      </div>
    </div>
  );
}
