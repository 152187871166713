import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsDirty } from "context/app/appActions";
import { setIsLeaveDialogEnabled } from "context/app/appActions";

export default function useIsFormDirty(dirtyFields) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLeaveDialogEnabled(true));

    return () => dispatch(setIsLeaveDialogEnabled(false));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      dispatch(setIsDirty(true));
    }
    if (Object.keys(dirtyFields).length === 0) {
      dispatch(setIsDirty(false));
    }
    // eslint-disable-next-line
  }, [Object.keys(dirtyFields).length]);

  return;
}
